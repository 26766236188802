import { SupportedChainId } from "./chains";

export const TX_LINK: { [key in SupportedChainId]: string } = {
    [SupportedChainId.MAINNET]: `https://etherscan.io/tx/`,
    [SupportedChainId.GOERLI]: `https://goerli.etherscan.io/tx/`,
    [SupportedChainId.SEPOLIA]: `https://sepolia.etherscan.io/tx/`
}
export const ADDRESS_LINK: { [key in SupportedChainId]: string } = {
  [SupportedChainId.MAINNET]: `https://etherscan.io/address/`,
  [SupportedChainId.GOERLI]: `https://goerli.etherscan.io/address/`,
  [SupportedChainId.SEPOLIA]: `https://sepolia.etherscan.io/address/`
}
export const NETWORK_NAME: { [key in SupportedChainId]: string } = {
  [SupportedChainId.MAINNET]: `mainnet`,
  [SupportedChainId.GOERLI]: `goerli`,
  [SupportedChainId.SEPOLIA]: `sepolia`
}
// sepolia
// export const GAS = {
//   "submit": "82544",
//   "submitAndStake": "155108",
//   "deposit": "94455",
//   "stake": "94828",
//   "redeem": "92904",
//   "withdraw": "126556",
//   "redeemAndWithdraw": "200641",
//   "claim": "82897"
// }
// mainnet
export const GAS = {
  "submit": "76646",
  "submitAndStake": "153066",
  "deposit": "94477",
  "stake": "94828",
  "redeem": "92904",
  "withdraw": "103350",
  "redeemAndWithdraw": "212842",
  "claim": "67521"
}