import { useCallback, useEffect, useState } from 'react'
import type { Web3ReactHooks } from '@web3-react/core'
import { Contract, ContractInterface } from '@ethersproject/contracts'
import type { BigNumber } from '@ethersproject/bignumber'
import { initContract } from '../utils'
import { Provider } from '@ethersproject/providers'
const useBalance = (
    provider?: ReturnType<Web3ReactHooks['useProvider']>,
    TokenConfig?: any,
    account?: string,
    chainId?: number,
    isSupportchain?:boolean,
    refresh:number = 0,
    IntervalTime:number = 10,
): BigNumber | undefined => {
    const [balance, setBalance] = useState<BigNumber | undefined>()
    const getBalance = useCallback(()=>{
        if (provider && account && chainId && isSupportchain) {
            let stale = false;
            const Contract = initContract((TokenConfig.networks as any)?.[chainId.toString()]?.address, TokenConfig.abi, provider);
            // void Promise.all(account.map((account) => provider.getBalance(account))).then((balances) => {
            //   console.log(balances)
            //   if (stale) return
            //   setBalance(balances)
            // })


            Contract.balanceOf(account).then((balance: (BigNumber | undefined)) => {
                // console.log(balance)
                if (stale) return
                setBalance(balance)
            })

            return () => {
                stale = true
                setBalance(undefined)
            }
        }
    },[provider,account,isSupportchain])
    useEffect(() => {
        getBalance()
        let refreshInterval = setInterval(() => {
            getBalance()
          }, 1000 * IntervalTime)
          return () => clearInterval(refreshInterval)
    }, [provider, account, chainId,isSupportchain,refresh])

    return balance
}
export default useBalance