import React, { createContext, useState, useEffect, useCallback, useRef } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
// import './App.css';
import "@arco-design/web-react/dist/css/arco.css";
import "./config.css";
// import "./coinbase.css"

import type { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import {
  useWeb3React,
  Web3ReactHooks,
  Web3ReactProvider,
} from "@web3-react/core";
import type { MetaMask } from "@web3-react/metamask";
import type { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";

import {
  coinbaseWallet,
  hooks as coinbaseWalletHooks,
} from "./connectors/coinbaseWallet";
import { hooks as metaMaskHooks, metaMask } from "./connectors/metaMask";
import {
  hooks as walletConnectV2Hooks,
  walletConnectV2,
} from "./connectors/walletConnectV2";

import Logo from "./assets/img/Aspida-Logo.svg";
import LogoLight from "./assets/img/Aspida Logo-Light.svg";
import Ethereum from "./assets/img/network/1.svg";
import Sepolia from "./assets/img/network/11155111.svg"
import Twitter from "./assets/img/Twitter.svg";
import Telegram from "./assets/img/Telegram.svg";
import Medium from "./assets/img/Medium.svg";
import Discord from "./assets/img/Discord.svg";
import Docs from "./assets/img/Docs.svg";
import TwitterDark from "./assets/img/TwitterDark.svg";
import TelegramDark from "./assets/img/TelegramDark.svg";
import MediumDark from "./assets/img/MediumDark.svg";
import DiscordDark from "./assets/img/DiscordDark.svg";
import DocsDark from "./assets/img/DocsDark.svg";
import Close from "./assets/img/Close.svg"
import menu_mint from "./assets/img/menu_mint.svg"
import {
  IconDown,
  IconSunFill,
  IconMoonFill,
  IconMore,
  IconCheckCircle,
  IconLaunch,
  IconCheck,
  IconInfoCircle,
  IconLeft,
} from "@arco-design/web-react/icon";
import { IconH5Mint,IconH6Stake,IconH6Withdrawal,IconH6Rewards,IconH6More } from "@arco-iconbox/react-lsd";
import { _initContract, getName } from "./utils";
import Entry from "./entry";
import {
  Layout,
  Menu,
  Switch as ArcoSwitch,
  Grid,
  Space,
  Dropdown,
  Avatar,
  Modal,
  Typography,
  Tooltip,
} from "@arco-design/web-react";
const Header = Layout.Header;
const Content = Layout.Content;
const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;
const Row = Grid.Row;
const Col = Grid.Col;
const { Title, Paragraph, Text } = Typography;
// import Switch from "./pages/ChangeMode"
import _Button from "./pages/Button";
import Mint from "./pages/Mint";
import Stake from "./pages/Stake";
import Withdrawal from "./pages/Withdrawal";
import Rewards from "./pages/Rewards";
import CoinbaseWalletCard from "./walletCards/connectorCards/CoinbaseWalletCard";
import MetaMaskCard from "./walletCards/connectorCards/MetaMaskCard";
import WalletConnectV2Card from "./walletCards/connectorCards/WalletConnectV2Card";
import WalletButton from "./walletCards/WalletButton";
import Connected from "./walletCards/Connected";
import priceFeedConfig from "./contracts/priceFeed.json";
import { BigNumber, ethers } from "ethers";
import CorePrimary from "./contracts/CorePrimary.json";
import { SupportedChainId } from "./constants/chains";
import MockstETHConfig from "./contracts/MockstETH.json";
import classNames from "classnames";
import classnames from "classnames";
import { NETWORK_NAME } from "./constants";
import Footer from "@arco-design/web-react/es/Layout/footer";
declare global {
  interface Window {
    withdrawal: NodeJS.Timer;
    willReceive: NodeJS.Timer;
    mint: NodeJS.Timer;
    stake: NodeJS.Timer;
    ETHFeed: NodeJS.Timer;
  }
}
interface ThemeContext {
  Theme?: string;
  ETHFeed?: Record<string, string>;
  Data?: Record<string, string>;
  setVisible?: (visible: boolean) => void;
}
const connectors: [
  MetaMask | WalletConnectV2 | CoinbaseWallet,
  Web3ReactHooks
][] = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectV2Hooks],
  [coinbaseWallet, coinbaseWalletHooks],
];

export const Context = createContext<ThemeContext>({
  Theme: "",
});
const networks:Record<string,any>={
  "1":{
    name:"Ethereum",
    icon:Ethereum
  },
  "5":{
    name:"Goerli",
    icon:Sepolia
  },
  "11155111":{
    name:"Sepolia",
    icon:Sepolia
  }
}

const App = () => {
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();
  const [mocking,setMocking] = useState<boolean>(false)
  const [Data,setData]=useState<Record<string, string>>({
    "apr": "0%",
    "stakers": "0",
    "saETHBalance": "0",
    "totalRewards": "0",
    "averageAPR": "0%",
    "aETHPrice": "0",
    "ETHPrice": "0",
    "saETHPrice": "0",
    "totalMintedTokens": "0",
    "totalStakedTokens": "0",
    "totalMintedTokensAmount": "0",
    "totalStakedTokensAmount": "0",
    "network": ""
  })
  const SwitchNetwork = useCallback(
    async (_chainId: number) => {
      try {
        _chainId !== chainId && connector.activate(_chainId);
      } catch (error) {
        console.log(error);
      }
    },
    [chainId]
  );
  const Faucet = useCallback(async() => {
    if(chainId && supportchain){
      
      try {
        setMocking(true)
        const MockstETH = _initContract((MockstETHConfig.networks as any)?.[chainId.toString()]?.address, MockstETHConfig.abi)
        const _provider = new ethers.providers.Web3Provider(window?.ethereum)
        const signer = _provider.getSigner(account)
        const receipt = await MockstETH.connect(signer).allocateTo();
        setMocking(false)
      } catch (error) {
        setMocking(false)
      }
      
    }
  },[chainId])
  
  const dropList = (
    <Menu className="mt-2">
      {/* <div className="leading-10 px-3 text-base font-medium fontColor">
        Select a network
      </div> */}
      {/* <Menu.Item key="5" onClick={() => SwitchNetwork(5)}>
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center gap-1">
            <Avatar className="mr-2" size={22}>
              <img alt="avatar" src={networks[5]["icon"]} />
            </Avatar>{" "}
            <span className="mr-2">{networks[5]["name"]}</span>
          </div>
          {
            (chainId === 5) &&
            <span className="success text-xl">
              <IconCheck />
            </span>
          }
        </div>
      </Menu.Item> */}
      {/* <Menu.Item key="11155111" onClick={() => SwitchNetwork(11155111)}>
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center gap-1">
            <Avatar className="mr-2" size={22}>
              <img alt="avatar" src={networks[11155111]["icon"]} />
            </Avatar>{" "}
            <span className="mr-2">{networks[11155111]["name"]}</span>
          </div>
          {
            (chainId === 11155111) &&
            <span className="success text-xl">
              <IconCheck />
            </span>
          }
        </div>
      </Menu.Item> */}
      <Menu.Item key="1" onClick={() => SwitchNetwork(1)}>
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center gap-1">
            <Avatar className="mr-2" size={22}>
              <img alt="avatar" src={networks[1]["icon"]} />
            </Avatar>{" "}
            <span className="mr-2">{networks[1]["name"]}</span>
          </div>
          {
            (chainId === 1) &&
            <span className="success text-xl">
              <IconCheck />
            </span>
          }
        </div>
      </Menu.Item>
    </Menu>
  );
  const supportchain = chainId
    ? Object.keys(CorePrimary.networks).includes((chainId as number).toString())
    : false;
  const [theme, setTheme] = useState<string>("");
  const dropMenu = (
    <Menu className="mt-2 MenuShadow">
      <Menu.Item key="Twitter">
        <Avatar className="mr-2" size={26}>
          <img alt="avatar" src={theme === 'dark' ? TwitterDark :Twitter} />
        </Avatar>{" "}
        <a href="https://twitter.com/aspida_net" target="_blank" rel="noreferrer" className="text-sm font-medium">
          Twitter
        </a>
      </Menu.Item>
      <Menu.Item key="Telegram">
        <Avatar className="mr-2" size={26}>
          <img alt="avatar" src={theme === 'dark' ? TelegramDark : Telegram} />
        </Avatar>{" "}
        <a href="https://twitter.com/aspida_net" target="_blank" rel="noreferrer" className="text-sm font-medium">
          Telegram
        </a>
      </Menu.Item>
      <Menu.Item key="Medium">
        <Avatar className="mr-2" size={26}>
          <img alt="avatar" src={theme === 'dark' ? MediumDark :Medium} />
        </Avatar>{" "}
        <a href="https://medium.com/@aspidabd" target="_blank" rel="noreferrer" className="text-sm font-medium">
          Medium
        </a>
      </Menu.Item>
      <Menu.Item key="Discord">
        <Avatar className="mr-2" size={26}>
          <img alt="avatar" src={theme === 'dark' ? DiscordDark : Discord} />
        </Avatar>{" "}
        <a href="https://discord.com/invite/UrYn3KjagY" target="_blank" rel="noreferrer" className="text-sm font-medium">
          Discord
        </a>
      </Menu.Item>
      <Menu.Item key="Docs">
        <Avatar className="mr-2" size={26}>
          <img alt="avatar" src={theme === 'dark' ? DocsDark : Docs} />
        </Avatar>{" "}
        <a href="https://docs.aspidanet.com/" target="_blank" rel="noreferrer" className="text-sm font-medium">
          Docs
        </a>
      </Menu.Item>
    </Menu>
  );
  const [ETHFeed, setETHFeed] = useState<any>({
    price: BigNumber.from("0"),
    decimals: 8,
  });
  // 刷新页面自动连接钱包
  useEffect(()=>{
    try {
      connector.activate()
    } catch (error) {
      console.log(error)
    }

  },[])
const FetchData = useCallback(async()=>{
  try {
    // console.log(`https://api.aspidanet.com/page_data/?chainId=${chainId ?? '11155111' }`)
    fetch(`https://api.aspidanet.com/page_data/?chainId=${chainId ?? '1' }`)
      .then(response => response.json())
      .catch(error => console.error('Error:', error))
      .then(response => {
        // console.log('Success:', response);
        setData(response)
    });
  } catch (error) {
    console.log(error)
  }
},[account,chainId])
  const fetchETHPrice = useCallback(async () => {
    if (chainId && account && window.ethereum) {
      try {
        const priceFeed = _initContract(
          (priceFeedConfig.networks as any)?.[chainId.toString()]?.address,
          priceFeedConfig.abi
        );
        const _provider = new ethers.providers.Web3Provider(window?.ethereum);
        const signer = _provider.getSigner(account);
        const receipt = await priceFeed.connect(signer).latestRoundData();
        const ETHdecimals = await priceFeed.connect(signer).decimals();
        setETHFeed({
          price: receipt.answer,
          decimals: ETHdecimals,
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [account, chainId, window.ethereum]);
  useEffect(() => {
    window.ETHFeed && clearInterval(window.ETHFeed);
    const target = () => {
      FetchData()
      if (chainId && account && window.ethereum) {
        fetchETHPrice();
      }
      return target;
    };
    window.ETHFeed = setInterval(target(), 1000 * 15);
    return () => {
      clearInterval(window.ETHFeed);
    };
  }, [account, chainId, window.ethereum]);
  const ChangeMode = (val: boolean) => {
    if (val) {
      document.body.setAttribute("arco-theme", "dark");
      setTheme("dark");
      window.localStorage.setItem("arco-theme","dark")
    } else {
      document.body.removeAttribute("arco-theme");
      window.localStorage.setItem("arco-theme","light")
      setTheme("");
    }

    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");

    darkThemeMq.addListener((e) => {
      if (e.matches) {
        document.body.setAttribute("arco-theme", "dark");
        window.localStorage.setItem("arco-theme","dark")
        setTheme("dark");
      } else {
        document.body.removeAttribute("arco-theme");
        window.localStorage.setItem("arco-theme","light")
        setTheme("");
      }
    });
  };
  useEffect(()=>{
    const localTheme = window.localStorage.getItem("arco-theme") ?? "light";
    if(localTheme === "light"){
      document.body.removeAttribute("arco-theme");
      window.localStorage.setItem("arco-theme","light")
      setTheme("");
    }else{
      document.body.setAttribute("arco-theme", "dark");
      window.localStorage.setItem("arco-theme","dark")
      setTheme("dark");
    }
  },[])
  const [visible, setVisible] = React.useState(false);
  // const [disabled, setDisabled] = React.useState(true);
  const location = useLocation();
  const [key, setKey] = useState<string[]>([location.pathname]);
  // 检测 路由 pathname 发生变化 跳转到 对应路由，导航显示对应路由信息
  useEffect(() => {
    setKey([location.pathname]);
  }, [location]);
  return (
    <Context.Provider value={{ Theme: theme, ETHFeed,Data, setVisible }}>
      {/* <Web3ReactProvider connectors={connectors}> */}
      <div
        className={classNames("layout-basic", {
          darkBg: theme === "dark",
          lightBg: theme === "",
        })}
      >
        {/* <Entry/> */}
        <div className="layout-content">
          <Layout>
            <Header>
              <Row className="menu flex align-middle justify-between">
                <div className="header menu_pc">
                  <Menu
                    mode="horizontal"
                    selectedKeys={key}
                    style={{ background: "transparent"}}
                    onClickMenuItem={(
                      key: string,
                      openKeys: string[],
                      keyPath: string[]
                    ) => setKey([key])}
                  >
                    <MenuItem
                    className="logo"
                      key="0"
                      style={{
                        padding: 0,
                        marginRight: 64,
                        background: "transparent",
                      }}
                      // disabled
                    >
                      <a href="https://aspidanet.com" target="_blank" rel="noreferrer">
                        <img
                          width="120"
                          height="24"
                          src={theme === "dark" ? Logo : LogoLight}
                          alt="Aspida"
                        />
                      </a>
                    </MenuItem>
                    <Link to="/">
                      <MenuItem key="/" style={{ background: "transparent",padding:'0 24px',lineHeight:'24px',fontSize:'16px' }} className="fontColor">
                        Mint
                      </MenuItem>
                    </Link>
                    <Link to="/Stake">
                      <MenuItem
                        key="/Stake"
                        style={{ background: "transparent",padding:'0 24px',lineHeight:'24px',fontSize:'16px' }}
                        className="fontColor"
                      >
                        Stake
                      </MenuItem>
                    </Link>
                    <Link to="/Withdrawal">
                      <MenuItem
                        key="/Withdrawal"
                        style={{ background: "transparent",padding:'0 24px',lineHeight:'24px',fontSize:'16px' }}
                        className="fontColor"
                      >
                        Withdrawal
                      </MenuItem>
                    </Link>
                    {/* <Link to="/Rewards" className="mr-4">
                        <MenuItem key="/Rewards" style={{ background: "transparent" }}>Rewards</MenuItem>
                      </Link> */}
                    {/* <Dropdown droplist={dropMenu} position='bl'>
                        ...
                      </Dropdown> */}
                    <Dropdown droplist={dropMenu} position="bl">
                      <></>
                      <IconMore
                        className="pt-1 verticalAlign"
                        style={{ fontSize: 24 }}
                      />
                    </Dropdown>
                  </Menu>
                </div>
                <div className="header text-end max-md:flex justify-between items-center">
                <a href="https://aspidanet.com" target="_blank" rel="noreferrer" className="hidden max-md:inline-block">
                        <img
                          width="80"
                          height="16"
                          src={theme === "dark" ? Logo : LogoLight}
                          alt="Aspida"
                        />
                </a>
                  <div className="p-3 gap-2 flex items-center justify-end dropdown-demo cursor-pointer max-md:px-0 max-md:gap-1">
                    {
                    (chainId === 11155111) &&
                    <Tooltip
                      position="bottom"
                      trigger="hover"
                      content="Faucet stETH"
                    >
                    <div className={classnames("p-2 mr-2 rounded-lg btnBg max-md:mr-0",{disabled:mocking})} style={{lineHeight:'normal'}} onClick={Faucet}>
                      Faucet
                    </div>
                    </Tooltip>
                    }
                    {
                      !supportchain ? 
                      <div className='flex items-center gap-2 mr-2 max-md:gap-1' style={{color:'red'}} onClick={async()=> {await connector.activate(1)}}>
                        <IconInfoCircle />
                        <span>unsupported</span>
                      </div>
                      :
                      <div className="max-md:hidden">
                      <Dropdown droplist={dropList} position="bl">
                        {
                        chainId && supportchain ?
                        <>
                          <Avatar className="mr-2 fontColor" size={22}>
                            <img alt="avatar" src={networks[chainId]["icon"]} />
                          </Avatar>{" "}
                          <span className="mr-2">{networks[chainId]["name"]}</span>
                        </>
                        :
                        <>
                          <Avatar className="mr-2 fontColor" size={22}>
                            <img alt="avatar" src={networks[11155111]["icon"]} />
                          </Avatar>{" "}
                          <span className="mr-2">{networks[11155111]["name"]}</span>
                        </>
                        }
                        <IconDown />
                      </Dropdown>
                      </div>
                    }
                    
                    <div
                      className={classnames("walletBtn p-2 rounded-lg leading-4 max-md:p-1",{connectWallet:!chainId})}
                      onClick={() =>
                        (chainId || !supportchain) && setVisible(true)
                      }
                    >
                      <WalletButton />
                    </div>
                    <ArcoSwitch
                      checkedText={<IconSunFill />}
                      uncheckedText={<IconMoonFill />}
                      defaultChecked
                      onChange={(val) => ChangeMode(val)}
                      checked={theme === "dark" ? true : false}
                    />
                  </div>
                </div>
              </Row>
            </Header>
            <Content className="flex flex-col justify-center">
              {
              chainId && !supportchain &&
              <div className="full-width flex justify-center items-center py-2 changeNetwork">
                {/* <span>Aspida is not supported on this network. Please switch Ethereum.</span> */}
                <span>Aspida is not supported on this network. Please switch Ethereum Mainnet network.</span>
                <div className="p-2 rounded-md ml-2 text-xs cursor-pointer bgWhite" onClick={async()=> {await connector.activate(1)}}>Change network</div>
              </div>
              }
              <div className="flex columns main justify-center align-middle">
                <Routes>
                  {/* <Route path="/">
                      <Route index element={<Mint setVisible={setVisible} />} /> */}
                  <Route path="/" element={<Mint setVisible={setVisible} />} />
                  <Route
                    path="Stake"
                    element={<Stake setVisible={setVisible} />}
                  />
                  <Route
                    path="Withdrawal"
                    element={<Withdrawal setVisible={setVisible} />}
                  />
                  {/* <Route path="Rewards" element={<Rewards />} /> */}
                  <Route path="*" element={<>404</>} />
                  {/* </Route> */}
                </Routes>
              </div>
              {/* <_Button/> */}
            </Content>
          </Layout>
        </div>
        <div className='footer hidden max-md:flex'>
              <Link to="/" className={classNames("footerItem",{menuActive:key?.[0]==='/'})}>
                {/* <img src={menu_mint} width={24}/> */}
                <IconH5Mint width="24" height="24"/>
                <span>Mint</span>
              </Link>
              <Link to="/Stake" className={classNames("footerItem",{menuActive:key?.[0]==='/Stake'})}>
              <IconH6Stake width="24" height="24"/>
                <span>Stake</span>
              </Link>
              <Link to="/Withdrawal" className={classNames("footerItem",{menuActive:key?.[0]==='/Withdrawal'})}>
              <IconH6Withdrawal width="24" height="24"/>
                <span>Withdrawal</span>
              </Link>
              {/* <Link to="/Rewards" className={classNames("footerItem",{menuActive:key?.[0]==='/Rewards'})}>
              <IconH6Rewards width="24" height="25"/>
                <span>Mint</span>
              </Link> */}
              {/* <div className="footerItem menuActive">
              <IconH6More width="24" height="25"/>
                <span>Mint</span>
              </div> */}
              <div className="footerItem more" id="_more">
                <Dropdown droplist={dropMenu} position="tl" trigger="click" getPopupContainer={() => document.getElementsByClassName("more")[0]}>
                  <IconH6More width="24" height="25"/>
                </Dropdown>
              <span>More</span>
              </div>
            </div>
        <Modal
          // style={{ cursor: 'pointer' }}
          className="wallet"
          title={
            <div className="text-left">
              <div>
                {chainId ? "Account" : "Connect wallet"}
                {/* {chainId && "Account"} */}
              </div>
              {/* <Paragraph>
                  Connect wallet in one click to start using Aspida
                </Paragraph> */}
            </div>
          }
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          autoFocus={false}
          footer={null}
          // closeIcon={<img src={Close}/>}
        >
          <div>
            {!chainId && <p className="gray -mt-3 mb-3">Connect wallet in one click to start using Aspida</p>}
            <div className="flex justify-between gap-2">
              {((getName(connector) === "MetaMask" && chainId) ||
                !chainId ||
                (chainId && !supportchain)) && <MetaMaskCard />}
              {((getName(connector) === "WalletConnect" && chainId) ||
                !chainId ||
                (chainId && !supportchain)) && <WalletConnectV2Card />}
              {((getName(connector) === "Coinbase" && chainId) ||
                !chainId ||
                (chainId && !supportchain)) && <CoinbaseWalletCard />}
            </div>
              {!chainId && <p className="grayNoFont font15 walletBg mt-3 p-4">View only permissions. We cannot do anything without your approval,How to use Aspida? <a href="#" style={{color:'#FBBF24'}}>Learn more <IconLaunch className="cursor-pointer ml-1"/></a></p>}
            </div>
        </Modal>
      </div>
      {/* </Web3ReactProvider> */}
    </Context.Provider>
  );
};

export default App;
