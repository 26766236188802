import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter} from "react-router-dom";
import 'preline';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import type { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { useWeb3React,Web3ReactHooks, Web3ReactProvider } from '@web3-react/core'
import type { MetaMask } from '@web3-react/metamask'
import type { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'

import { coinbaseWallet, hooks as coinbaseWalletHooks } from './connectors/coinbaseWallet'
import { hooks as metaMaskHooks, metaMask } from './connectors/metaMask'
import { hooks as walletConnectV2Hooks, walletConnectV2 } from './connectors/walletConnectV2'

const connectors: [MetaMask | WalletConnectV2 | CoinbaseWallet, Web3ReactHooks][] = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectV2Hooks],
  [coinbaseWallet, coinbaseWalletHooks]
]
declare global {
  interface Window {
    clipboardData: any;
  }
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <HashRouter>
    <Web3ReactProvider connectors={connectors}>
      <App />
    </Web3ReactProvider>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
