export enum SupportedChainId {
  MAINNET = 1,
  GOERLI = 5,
  SEPOLIA = 11155111,
  // BSC = 56,
  // BSCTEST = 97,
  // ARBITRUM_ONE = 42161,
  // ARBITRUM_RINKEBY = 421611,
  // OPTIMISM = 10,
  // OPTIMISTIC_KOVAN = 69,
  // POLYGON = 137,
  // AVALANCHE = 43114,
  // KAVA_TESTNET = 2221,
  // KAVA = 2222,
  // CONFLUX_TEST = 71,
  // CONFLUX_SPACE = 1030,
  // ZKSYNC_ERA = 324,
  // BASE = 8453
}

export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  SupportedChainId.MAINNET,
  SupportedChainId.GOERLI,
  SupportedChainId.SEPOLIA
  // SupportedChainId.BSC,
  // SupportedChainId.BSCTEST,
  // SupportedChainId.ARBITRUM_ONE,
  // SupportedChainId.ARBITRUM_RINKEBY,
  // SupportedChainId.OPTIMISM,
  // SupportedChainId.OPTIMISTIC_KOVAN,
  // SupportedChainId.POLYGON,
  // SupportedChainId.AVALANCHE,
  // SupportedChainId.KAVA_TESTNET,
  // SupportedChainId.KAVA,
  // SupportedChainId.CONFLUX_TEST,
  // SupportedChainId.CONFLUX_SPACE,
  // SupportedChainId.ZKSYNC_ERA,
  // SupportedChainId.BASE
]
