import type { BigNumber } from '@ethersproject/bignumber'
import { formatEther } from '@ethersproject/units'
import { useWeb3React, type Web3ReactHooks } from '@web3-react/core'
import { useEffect, useState } from 'react'
import accountLog from "../assets/img/accountLog.svg"
import useBalanceETH from "../hooks/useBalanceETH";
import CorePrimary from "../contracts/CorePrimary.json";
import { SupportedChainId } from "../constants/chains";
import { IconInfoCircle } from '@arco-design/web-react/icon'

export default function WalletButton() {
  const { connector,account,chainId,provider,ENSName,isActive,isActivating } = useWeb3React()
  const balance = useBalanceETH(provider, account)
  const supportchain = chainId ? Object.keys(CorePrimary.networks).includes((chainId as number).toString()) : false;
  // if (account === undefined) return null

  return (
    <div>
        {
            account ?
              // <ul key={account} style={{ margin: 0, overflow: 'hidden', textOverflow: 'ellipsis' }}>
              //   {ENSName ?? account}
              //   {balance ? ` (Ξ${formatEther(balance)})` : null}
              // </ul>
              // !supportchain ? 
              // <div className='flex items-center gap-2' style={{color:'red'}}>
              //   <IconInfoCircle />
              //   <span>unsupported</span>
              // </div>
              // :
              <div key={account} className='flex justify-between items-center'>
                <span>{balance ? Number((formatEther(balance))).toFixed(4) + ' ETH' : null}</span>
                <img src={accountLog} className="mr-2 w-6 max-md:w-4 max-md:mr-1"/>
                <span>{ENSName ?? (account.substring(0,5) + '...' + account.substring(account?.length-4,account?.length))}</span>
              </div>
            :'Connect wallet'
        }
    </div>
  )
}
