import type { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import type { Web3ReactHooks } from '@web3-react/core'
import type { GnosisSafe } from '@web3-react/gnosis-safe'
import type { MetaMask } from '@web3-react/metamask'
import type { Network } from '@web3-react/network'
import type { WalletConnect } from '@web3-react/walletconnect'
import type { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'
import CorePrimaryConfig from "../contracts/CorePrimary.json"
import { getName } from '../utils'
import { Account } from './Account'
import { Chain } from './Chain'
import { ConnectWithSelect } from './ConnectWithSelect'
import { Status } from './Status'
import { ADDRESS_LINK } from "../constants/index";

// import { IconLaunch,IconCopy } from '@arco-design/web-react/icon'
import { useCallback, useState } from 'react'
import { IconExternalLink,IconCopy } from '@arco-iconbox/react-lsd'

interface Props {
  connector: MetaMask | WalletConnect | WalletConnectV2 | CoinbaseWallet | Network | GnosisSafe
  activeChainId: ReturnType<Web3ReactHooks['useChainId']>
  chainIds?: ReturnType<Web3ReactHooks['useChainId']>[]
  isActivating: ReturnType<Web3ReactHooks['useIsActivating']>
  isActive: ReturnType<Web3ReactHooks['useIsActive']>
  error: any
  setError: (arg0: any) => void
  ENSName: ReturnType<Web3ReactHooks['useENSName']>
  ENSNames: ReturnType<Web3ReactHooks['useENSNames']>
  provider?: ReturnType<Web3ReactHooks['useProvider']>
  accounts?: string[]
  account?: string
}

export function Card({
  connector,
  activeChainId,
  chainIds,
  isActivating,
  isActive,
  error,
  setError,
  ENSName,
  ENSNames,
  account,
  accounts,
  provider,
}: Props) {
  const supportchain = activeChainId ? Object.keys(CorePrimaryConfig.networks).includes((activeChainId as number).toString()) : false;
  const [isCopy, setIsCopy] = useState<boolean>(false);
  const copyToClipboard = useCallback((s: string) => {
    setIsCopy(true);
    if (window.clipboardData) {
      window.clipboardData.setData("text", s);
    } else {
      (function (s) {
        document.oncopy = (e: any) => {
          e.clipboardData.setData("text", s);
          e.preventDefault();
          document.oncopy = null;
        };
      })(s);
      document.execCommand("Copy");
    }
    setTimeout(() => {
      setIsCopy(false);
    }, 500);
  }, []);
  return (
    <div
      className='walletBg p-6 max-md:p-3'
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: 'auto',
        position:'relative',
        flex:1
      }}
    >
      {
        (activeChainId && supportchain) &&
        <div className='flex flex-col justify-between'>
          {/* <b>{getName(connector)}</b> */}
          <div className='gray font18 max-md:text-sm'>
            {/* <Status isActivating={isActivating} isActive={isActive} error={error} /> */}
            Connected with {getName(connector)}
          </div>
          <div className='flex flex-row justify-between items-center my-3'>
            <Account account={account} provider={provider} ENSName={ENSName} />
            {/* <Chain chainId={activeChainId} /> */}
          </div>
          <div className='flex flex-row justify-between items-center cursor-pointer text-base w-125percent max-md:text-sm'>
            <div className={isCopy ? "twinkling flex items-center" : "flex items-center"} style={{ color: '#3B82F6' }} onClick={() => copyToClipboard(account as string)}>
              {/* <IconCopy /> */}
              <IconCopy />
              <span className="ml-1">Copy address</span>
            </div>
            <a href={activeChainId ? `${(ADDRESS_LINK as any)[activeChainId as number]}${account}` : '#'} target="_blank" rel="noreferrer" className="flex items-center ml-3" style={{ color: '#3B82F6' }}>
              {/* <IconLaunch /> */}
              <IconExternalLink />
              <span className="ml-1">View on Etherscan</span>
            </a>
          </div>
        </div>
      }

      <ConnectWithSelect
        connector={connector}
        activeChainId={activeChainId}
        chainIds={chainIds}
        isActivating={isActivating}
        isActive={isActive}
        error={error}
        setError={setError}
      />
    </div>
  )
}
