import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import classnames from "classnames";
import { Context } from "../App";
import {
  useWeb3React,
  Web3ReactHooks,
  Web3ReactProvider,
} from "@web3-react/core";
import type { Provider } from "@web3-react/types";
import { ethers } from "ethers";
import { parseFixed, formatFixed, BigNumber } from "@ethersproject/bignumber";
import { formatEther } from "@ethersproject/units";
import {
  Typography,
  Grid,
  Form,
  Input,
  Image,
  Divider,
  Tooltip,
  Message,
  Modal,
} from "@arco-design/web-react";
const { Title, Paragraph, Text } = Typography;
const Row = Grid.Row;
const Col = Grid.Col;
const FormItem = Form.Item;
import {
  IconMoreVertical,
  IconPlusCircleFill,
  IconSync,
  IconClose,
  IconCheck,
  IconLaunch,
  IconUser,
  IconSearch,
  IconInfoCircle,
  IconArrowRight,
} from "@arco-design/web-react/icon";
import {
  AddTokenToMetamask,
  formatNumberNotCarry,
  formatNumber,
  initContract,
  _initContract,
  multicall,
  multicall_target,
  formateNormalNumber,
} from "../utils";
import _Input from "./_Input";
import useBalance from "../hooks/useBalance";
import useAllowance from "../hooks/useAllowance";
import aETHConfig from "../contracts/aETH.json";
import saETHConfig from "../contracts/saETH.json";
import CorePrimaryConfig from "../contracts/CorePrimary.json";
import aETH from "../assets/img/token/aETH.svg";
import saETH from "../assets/img/token/saETH.svg";
import exchangeDark from "../assets/img/exchangeDark.svg";
import exchange from "../assets/img/exchange.svg";
import sync from "../assets/img/sync.svg";
import success from "../assets/img/success.svg";
import fail from "../assets/img/fail.svg";
import Close from "../assets/img/Close.svg";
import { Link } from "react-router-dom";
import { GAS, TX_LINK } from "../constants";
import { CHAINS } from "../chains";
import { IconAddToken, IconDarkAArriveB, IconDone, IconExternalLink, IconFail, IconResearch } from "@arco-iconbox/react-lsd";

// stake Input
interface CustomStakeProps {
  status: boolean;
  val: string;
}
const CustomStakeInput: React.FC<CustomStakeProps> = (props: any) => {
  const value = props.val || props.value || {};
  const handleChange = (newValue: any) => {
    props.onChange && props.onChange(newValue);
  };
  useEffect(() => {
    handleChange({ input: "" });
  }, [props.status]);
  return (
    <Input
      value={value.input}
      onChange={(v) => {
        handleChange({
          ...value,
          input: v
            .trim()
            .replace(".", "$#$")
            .replace(/\./g, "")
            .replace("$#$", ".")
            .replace(/[^\d.]/g, "")
            .replace(/^\./g, "")
            .replace(/^(\-)*(\d+)\.(\d\d\d\d\d\d\d\d).*$/, "$1$2.$3"),
        });
      }}
      disabled={props.status ? true : false}
      className="inputBg"
      style={{ width: "100%", height: 40 }}
      addBefore={
        <>
          <img className="inline-block w-30 mr-2 max-md:mr-1 max-md:w-6" src={aETH} />
          <span>aETH</span>
        </>
      }
      beforeStyle={{ padding: 0, border: 0 }}
      // allowClear
      placeholder={props.status ? "" : "0.0"}
    />
  );
};
// unStake input
interface CustomUnStakeProps {
  status: boolean;
  val: string;
}
const CustomUnStakeInput: React.FC<CustomUnStakeProps> = (props: any) => {
  const value = props.val || props.value || {};
  const handleChange = (newValue: any) => {
    props.onChange && props.onChange(newValue);
  };
  useEffect(() => {
    handleChange({ input: "" });
  }, [props.status]);
  return (
    <Input
      value={value.input}
      onChange={(v) => {
        handleChange({
          ...value,
          input: v
            .trim()
            .replace(".", "$#$")
            .replace(/\./g, "")
            .replace("$#$", ".")
            .replace(/[^\d.]/g, "")
            .replace(/^\./g, "")
            .replace(/^(\-)*(\d+)\.(\d\d\d\d\d\d\d\d).*$/, "$1$2.$3"),
        });
      }}
      disabled={props.status ? false : true}
      style={{ width: "100%", height: 40 }}
      addBefore={
        <>
          <img className="inline-block w-30 mr-2 max-md:mr-1 max-md:w-6" src={saETH} />
          <span>saETH</span>
        </>
      }
      beforeStyle={{ padding: 0, border: 0 }}
      // allowClear
      placeholder={props.status ? "0.0" : ""}
    />
  );
};

interface StakeProps {
  setVisible: (visible: boolean) => void;
}
const Stake: React.FC<StakeProps> = ({ setVisible }) => {
  const context = useContext(Context);
  const { provider, connector, account, chainId, hooks } = useWeb3React();
  const supportchain = chainId
    ? Object.keys(CorePrimaryConfig.networks).includes(
        (chainId as number).toString()
      )
    : false;
  // const {useSelectedProvider} = hooks
  // const _provider = useSelectedProvider(connector)
  // console.log("Web3ReactProvider",Web3ReactProvider)
  const [stakevisible, setStakevisible] = React.useState(false);
  // 0:初始状态 1: success 2: fail
  const [stakeTransactionStatus, setStakeTransactionStatus] = useState<number>(
    0
  );
  const [unstakevisible, setUnstakevisible] = React.useState(false);
  // 0:初始状态 1: success 2: fail
  const [unStakeTransactionStatus, setUnStakeTransactionStatus] = useState<
    number
  >(0);

  const [errorTxt, setErrorTxt] = useState<any>("");
  const [hash, setHash] = useState<string | undefined>(undefined);

  const [refreshAllowance, setrefreshAllowance] = useState<number>(0);
  const aETH_allowance = useAllowance(
    aETHConfig,
    saETHConfig,
    refreshAllowance
  );
  const [approving, setApproving] = useState<boolean>(false);
  const [form_stake] = Form.useForm();
  const [form_unStake] = Form.useForm();

  const [refreshBalance, setrefreshBalance] = useState<number>(0);
  const aETH_balance = useBalance(
    provider,
    aETHConfig,
    account,
    chainId,
    supportchain
  );
  const saETH_balance = useBalance(
    provider,
    saETHConfig,
    account,
    chainId,
    supportchain
  );

  const [staking, setStaking] = useState<boolean>(false);
  const [stakeMax, setStakeMax] = useState<boolean>(false);
  const [unStakeMax, setunStakeMax] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);
  const [rotate, setRotate] = useState<boolean>(false);
  const Change = useCallback(() => {
    setStatus(!status);
    setRotate(true);
    setTimeout(() => {
      setRotate(false);
    }, 500);
  }, [status]);

  const [values1, setValues1] = useState<any>({
    select: "aETH",
    input: undefined,
  });
  const [valueinModal1, setValueinModal1] = useState<any>(undefined);
  const [values2, setValues2] = useState<any>({
    select: "saETH",
    input: undefined,
  });
  const [valueinModal2, setValueinModal2] = useState<any>(undefined);
  const [willReceiveRate, setWillReceiveRate] = useState<BigNumber | undefined>(
    undefined
  );
  const _willReceiveRate = useCallback(async () => {
    if (chainId && account && provider && supportchain) {
      const saETH = initContract(
        (saETHConfig.networks as any)?.[chainId.toString()]?.address,
        saETHConfig.abi,
        provider
      );
      if (!status) {
        const aETH_saETH = saETH.previewDeposit(BigNumber.from(10).pow(18));
        // console.log(aETH_saETH.toString())
        return aETH_saETH;
        
        // return BigNumber.from(10).pow(18).mul(2)
      } else {
        const saETH_aETH = saETH.previewMint(BigNumber.from(10).pow(18));
        return saETH_aETH;
      }
    }
  }, [status, chainId, account, provider, supportchain]);
  const getReceive = useCallback(async () => {
    const _w = await _willReceiveRate()
    setWillReceiveRate(_w);
    // console.log("_willReceiveRate", (_w)?.toString());
  }, [_willReceiveRate]);
  useEffect(() => {
    if (chainId && account && provider && supportchain) {
      getReceive();
    }
  }, [status, unStakeMax, chainId, account, provider, supportchain]);
  // const [totalaETHStaked,settotalaETHStaked] = useState<BigNumber | undefined>(undefined)
  // const [saETHMarketCap,setsaETHMarketCap] = useState<BigNumber | undefined>(undefined)
  const OnApprove = useCallback(async () => {
    if (!account || !chainId || !window.ethereum) return;
    setApproving(true);
    const aETHContract = _initContract(
      (aETHConfig.networks as any)?.[chainId.toString()]?.address,
      aETHConfig.abi
    );

    const _provider = new ethers.providers.Web3Provider(window?.ethereum);
    const signer = _provider.getSigner(account);
    try {
      const receipt = await aETHContract
        .connect(signer)
        .approve(
          (saETHConfig.networks as any)?.[chainId.toString()]?.address,
          ethers.constants.MaxUint256
        );
      // console.log("receipt: ", receipt);
      setApproving(false);

      // const refreshId = setTimeout(() => {
      setrefreshAllowance(Math.random());
      //   clearTimeout(refreshId)
      // }, 500)

      // receipt.getTransaction((hash: string) => {
      //   console.log("transactionHash: ", hash);
      // })
      // receipt.getTransactionReceipt()((receipt: any) => {
      //   console.log("receipt: ", receipt);
      //   const refreshId = setTimeout(() => {
      //     setrefreshAllowance(Math.random())
      //     clearTimeout(refreshId)
      //   }, 500)
      // })
    } catch (error) {
      setApproving(false);
      console.log("error", error);
    }
  }, [account, chainId]);
  const OnStake = useCallback(async () => {
    if (form_stake && form_unStake && chainId && account && window?.ethereum) {
      try {
        if (!values1.input) return;
        await form_stake.validate();
        await form_unStake.validate();
        setStaking(true);
        setStakeTransactionStatus(0);
        setHash(undefined);
        setErrorTxt("");
        const saETHContract = _initContract(
          (saETHConfig.networks as any)?.[chainId.toString()]?.address,
          saETHConfig.abi
        );
        const _provider = new ethers.providers.Web3Provider(window?.ethereum);
        const signer = _provider.getSigner(account);
        try {
          setStakevisible(true);
          const receipt = await saETHContract
            .connect(signer)
            .deposit(
              stakeMax ? aETH_balance : parseFixed(values1.input, 18),
              account
            );
            const re = await receipt.wait()
            // console.log("re",re)
          if(re.status === 1){
            setStaking(false);
            setStakeTransactionStatus(1);
            setValues1({ ...values1, input: undefined });
            setValues2({ ...values2, input: undefined });
            setrefreshBalance(Math.random());
          }else{
            setStaking(false);
            setStakeTransactionStatus(3);
            setErrorTxt("Failed to send request withdrawal")
          }
          setHash(receipt.hash);
        } catch (error) {
          console.log("error", error);
          setStaking(false);
          setStakeTransactionStatus(2);
          setErrorTxt("user rejected transaction")
        }
        // Message.info('校验通过，提交成功！');
      } catch (_) {
        // console.log(form_stake.getFieldsError());
        // console.log(form_unStake.getFieldsError());
        setStaking(false);
        // Message.error('校验失败，请检查字段！');
      }
    }
  }, [
    values1,
    values2,
    stakeMax,
    aETH_balance,
    chainId,
    account,
    window?.ethereum,
  ]);

  const OnUnStake = useCallback(async () => {
    if (form_stake && form_unStake && chainId && account && window?.ethereum) {
      try {
        if (!values2.input) return;
        await form_stake.validate();
        await form_unStake.validate();
        setStaking(true);
        setUnStakeTransactionStatus(0);
        setHash(undefined);
        setErrorTxt("");
        const saETHContract = _initContract(
          (saETHConfig.networks as any)?.[chainId.toString()]?.address,
          saETHConfig.abi
        );
        const _provider = new ethers.providers.Web3Provider(window?.ethereum);
        const signer = _provider.getSigner(account);
        try {
          setUnstakevisible(true);
          const receipt = await saETHContract
            .connect(signer)
            .redeem(
              unStakeMax ? saETH_balance : parseFixed(values2.input, 18),
              account,
              account
            );
            const re = await receipt.wait()
            // console.log("re",re)
            if(re.status === 1){
              setStaking(false);
              setUnStakeTransactionStatus(1);
              setValues1({ ...values1, input: undefined });
              setValues2({ ...values2, input: undefined });
              setrefreshBalance(Math.random());
            }else{
              setStaking(false);
              setUnStakeTransactionStatus(3);
              setErrorTxt("Failed to send request withdrawal")
            }
            setHash(receipt.hash);
        } catch (error) {
          console.log("error", error);
          setStaking(false);
          setUnStakeTransactionStatus(2);
          setErrorTxt("user rejected transaction")
        }
        // Message.info('校验通过，提交成功！');
      } catch (_) {
        // console.log(form_stake.getFieldsError());
        // console.log(form_unStake.getFieldsError());
        // Message.error('校验失败，请检查字段！');
      }
    }
  }, [
    values1,
    values2,
    unStakeMax,
    saETH_balance,
    chainId,
    account,
    window?.ethereum,
  ]);
  useEffect(() => {
    if (!stakeTransactionStatus || !unStakeTransactionStatus) {
      setStakeTransactionStatus(0);
      setUnStakeTransactionStatus(0);
      setHash(undefined);
      setErrorTxt("");
    }
  }, [stakevisible, unstakevisible]);
  // const FetchData = useCallback(async () => {
  //   if (provider && chainId && account && window.ethereum && context.ETHFeed) {
  //     try {
  //       const rpcUrl = CHAINS[chainId]?.urls[Math.floor(Math.random()*CHAINS[chainId]?.urls.length)]
  //     // // const _provider = new ethers.providers.Web3Provider(window?.ethereum)
  //     const multical_params = [
  //       {
  //         target:(saETHConfig.networks as any)?.[chainId.toString()]?.address,
  //         call:["totalAssets()(uint256)"],
  //         key:"_totalaETHStaked"
  //       }
  //     ]
  //     const res = await multicall(rpcUrl,multicall_target[chainId], multical_params)
  //     if(!res) return;
  //     const _saETHMarketCap = res.results.transformed._totalaETHStaked.mul(context.ETHFeed.price)
  //       // const saETH = initContract((saETHConfig.networks as any)?.[chainId.toString()]?.address, saETHConfig.abi, provider);
  //       // const _totalaETHStaked = await saETH.totalAssets();
  //       // const _saETHMarketCap = (await saETH.totalAssets()).mul(context.ETHFeed.price)
  //       settotalaETHStaked(res.results.transformed._totalaETHStaked)
  //       setsaETHMarketCap(_saETHMarketCap)
  //       setrefreshBalance(Math.random())
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  // }, [provider, chainId, account,context.ETHFeed])
  useEffect(() => {
    window.stake && clearInterval(window.stake)
    const target = () => {
      if (provider && chainId && account && supportchain && context.ETHFeed) {
        // FetchData();
        getReceive();
      }
      return target
    }
    window.stake = setInterval(target(), 1000 * 15);
    return () => {
      clearInterval(window.stake);
    };
  }, [provider, chainId, account,supportchain,context.ETHFeed])
  const [maxConst, setMaxConst] = useState<string | undefined>(undefined);
  const FetchGas = useCallback(
    async () => {
      if (
        provider &&
        chainId &&
        account &&
        window.ethereum &&
        supportchain &&
        context.ETHFeed
      ) {
        try {
          // const saETHContract = _initContract(
          //   (saETHConfig.networks as any)?.[chainId.toString()]?.address,
          //   saETHConfig.abi
          // );
          const { gasPrice } = await provider.getFeeData();
          // const _provider = new ethers.providers.Web3Provider(window?.ethereum);
          // const signer = _provider.getSigner(account);
          if (status) {
            // const gas = await saETHContract
            //   .connect(signer)
            //   .estimateGas.redeem(value, account, account);
            const Cost = BigNumber.from(GAS.redeem)
              .mul(BigNumber.from(gasPrice))
              .mul(BigNumber.from(context.ETHFeed.price))
              .toString();
            setMaxConst(Cost);
          } else {
            // const gas = await saETHContract
            //   .connect(signer)
            //   .estimateGas.deposit(value, account);
            const Cost = BigNumber.from(GAS.stake)
              .mul(BigNumber.from(gasPrice))
              .mul(BigNumber.from(context.ETHFeed.price))
              .toString();
            setMaxConst(Cost);
          }
        } catch (error) {
          console.log(error);
          setMaxConst(undefined);
        }
      }
    },
    [provider, chainId, account, supportchain, context.ETHFeed, status]
  );
  useEffect(() => {
    FetchGas();
  }, [provider, chainId, account, supportchain, context.ETHFeed, status]);
  useEffect(() => {
    setStakevisible(false)
    setUnstakevisible(false)
    setValues1({ select: "aETH", input: undefined });
    setValues2({ select: "saETH", input: undefined });
    form_stake.resetFields();
    form_unStake.resetFields();
  }, [account, chainId]);
  return (
    <div className="w-490">
      <div className="text-center">
        <div className="text-3xl font-medium mt-2 max-md:mt-4 max-md:text-xl">saETH Staking</div>
        <div className="secTitle max-md:mb-4 max-md:text-xs">Stake aETH, Earn saETH.</div>
      </div>
      {chainId && supportchain && (
        <Typography
          className={classnames("white", {
            ModuleDark: context.Theme === "dark",
            ModuleLight: context.Theme === "",
          })}
        >
          <div className="ModuleBgInner p-6 m-auto">
            <div className="white font22 font-semibold max-md:text-base">Portfolios</div>
            <div className="flex flex-col">
              <div className="flex flex-row">
                <div className="flex-col flex-1 justify-around align-middle my-4 max-md:my-3">
                  <div className="opacity-60 mb2-5 leading-normal text-sm">
                    aETH balance
                  </div>
                  <div className="flex items-center gap-2 mb-4">
                    <div className="cursor-pointer text-22 font-semibold max-md:text-base">
                      {aETH_balance && (
                        <Tooltip
                          position="bottom"
                          trigger="hover"
                          content={formateNormalNumber(
                            formatEther(aETH_balance),
                            16
                          )}
                        >
                          {formatNumber(formatEther(aETH_balance), 4) + " aETH"}
                        </Tooltip>
                      )}
                    </div>
                    <div className="AddTokens">
                      <Tooltip
                        position="bottom"
                        trigger="hover"
                        content="Add tokens to wallet"
                      >
                        <IconAddToken
                          className="cursor-pointer arco-icon-h5"
                          onClick={async (e) =>
                            await AddTokenToMetamask(
                              e,
                              (aETHConfig.networks as any)?.[
                                (chainId as number).toString()
                              ]?.address,
                              "aETH",
                              "18",
                              aETH,
                              connector.provider as Provider
                            )
                          }
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="flex-col flex-1 justify-around align-middle my-4 max-md:my-3">
                  <div className="opacity-60 mb2-5 leading-normal text-sm">
                    saETH balance
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="cursor-pointer text-22 font-semibold max-md:text-base">
                      {saETH_balance && (
                        <Tooltip
                          position="bottom"
                          trigger="hover"
                          content={formateNormalNumber(
                            formatEther(saETH_balance),
                            16
                          )}
                        >
                          {formatNumber(formatEther(saETH_balance), 4) +
                            " saETH"}
                        </Tooltip>
                      )}
                    </div>
                    <div className="AddTokens">
                      <Tooltip
                        position="bottom"
                        trigger="hover"
                        content="Add tokens to wallet"
                      >
                        <IconAddToken
                          className="cursor-pointer arco-icon-h5"
                          onClick={async (e) =>
                            await AddTokenToMetamask(
                              e,
                              (saETHConfig.networks as any)?.[
                                (chainId as number).toString()
                              ]?.address,
                              "saETH",
                              "18",
                              saETH,
                              connector.provider as Provider
                            )
                          }
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="opacity-60 leading-normal text-sm">
                    ≈{" "}
                    {saETH_balance && willReceiveRate
                      ? Number(
                          formatEther(
                            saETH_balance
                              .mul(willReceiveRate)
                              .div(BigNumber.from(10).pow(18))
                          )
                        ).toFixed(4)
                      : "-"}{" "}
                    aETH
                    {/* formatNumber(formatFixed(saETH_balance.mul(willReceiveRate).div(BigNumber.from(10).pow(18)),18),4)} */}
                  </div>
                </div>
              </div>
              {/* <Divider /> */}
              <div className="divider mb-4 max-md:mb-3"></div>
              <Col span={12} className="flex-col justify-around align-middle">
                <Col className="opacity-60 leading-normal text-sm">APR</Col>
                <Row className="gap-2 font22 font-semibold mb-4 max-md:mb-0 max-md:text-base">
                  <div>{context.Data?.apr ? context.Data?.apr : "-"}</div>
                </Row>
              </Col>
            </div>
          </div>
          {/* <Row className="flex align-middle leading-8 mt-2">
          <Col className="gray" span={12}>ETH balance</Col>
          <Col className="gray" span={12}>aETH balance</Col>
        </Row>
        <Row className="flex align-middle leading-8">
          <Col span={12}>0.3472 ETH</Col>
          <Col span={12} >0.0 aETH</Col>
        </Row> */}
        </Typography>
      )}
      <Typography
        className={classnames("p-6 _shadowBig flex flex-col gap-4 fontColor max-md:p-4", {
          sectionBgNoRadius: chainId,
          sectionBg: !chainId,
        })}
      >
        {status ? (
          <p className="max-md:text-base">UnStake saETH</p>
        ) : (
          <p className="max-md:text-base">Stake aETH</p>
        )}

        {/* <_Input key="Input1" onSelect={onSelect1} options={options1}/> */}
        <div className="flex flex-col justify-around">
          <Form
            form={form_stake}
            className={status ? "inputBg p-4 order-1 max-md:p-3" : "inputBg p-4 -order-1 max-md:p-3"}
            onValuesChange={(_, v) => {
              setValues1(v.stake);
              setStakeMax(false);
              setValues2({
                input:
                  v.stake.input && willReceiveRate
                    ? parseFloat(
                        Number(
                          formatFixed(
                            parseFixed(v.stake.input, 18).mul(willReceiveRate),
                            36
                          )
                        ).toFixed(8)
                      )
                    : undefined,
              });
              setValueinModal1(v.stake?.input);
              setValueinModal2(
                v.stake.input && willReceiveRate
                  ? formatFixed(
                      parseFixed(v.stake.input, 18).mul(willReceiveRate),
                      36
                    )
                  : undefined
              );
            }}
          >
            <p className="gray pb-2">{status ? "Receive" :"Send"}</p>
            <FormItem
              className="stake"
              field="stake"
              rules={[
                {
                  validator: (val, cb) => {
                    // console.log(val)
                    if (val.input && parseFixed(val.input, 18).eq(0)) {
                      cb("Amount is required");
                    }
                    if (
                      val.input &&
                      aETH_balance &&
                      parseFixed(val.input, 18).gt(aETH_balance)
                    ) {
                      cb("aETH Insufficient liquidity");
                    }
                    // cb();
                  },
                },
              ]}
              extra={
                chainId &&
                  <div className="flex justify-between items-center w-full">
                  {!status ? 
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      aETH_balance &&
                        setValues1({
                          ...values1,
                          input: parseFloat(
                            formateNormalNumber(formatEther(aETH_balance), 8)
                          ).toString(),
                        });
                      setValues2({
                        ...values2,
                        input:
                          aETH_balance && willReceiveRate
                            ? parseFloat(
                                Number(
                                  formatFixed(
                                    aETH_balance.mul(willReceiveRate),
                                    36
                                  )
                                ).toFixed(8)
                              )
                            : undefined,
                      });
                      setStakeMax(true);
                      setValueinModal1(
                        formatEther(aETH_balance ?? 0).toString()
                      );
                      setValueinModal2(
                        aETH_balance && willReceiveRate
                          ? formatFixed(aETH_balance.mul(willReceiveRate), 36)
                          : undefined
                      );
                    }}
                  >
                    Max:{" "}
                    {aETH_balance
                      ? formatNumber(formatEther(aETH_balance), 4)
                      : "-"}
                  </span>
                  :<span></span>
                  }
                  {values1.input && context.Data?.aETHPrice && <span>≈&nbsp;{formatNumber(formatFixed(parseFixed(values1.input, 18).mul(parseFixed(context.Data?.aETHPrice,18)).div(BigNumber.from(10).pow(18)), 18), 2)}</span>}
                </div>
              }
              wrapperCol={{ span: 24 }}
              initialValue={values1}
            >
              <CustomStakeInput status={status} val={values1} />
            </FormItem>
            {/* <Typography.Paragraph>
            <p>Form Data:</p>
            <pre>{aETH_balance?.toString()}</pre>
            <pre>{values1.input}</pre>
            <pre>{JSON.stringify(values1, null, 2)}</pre>
          </Typography.Paragraph> */}
          </Form>

          <div
            className="IconSync flex justify-center items-center cursor-pointer -m-4"
            style={{ alignSelf: "center" }}
            onClick={Change}
          >
            {/* <IconSync fontSize={28} spin={rotate} /> */}
            <img
              src={context.Theme === "dark" ? exchangeDark : exchange}
              alt=""
              className={rotate ? "rotate" : ""}
            />
          </div>
          <Form
            form={form_unStake}
            className={status ? "inputBg p-4 -order-1 max-md:p-3" : "inputBg p-4 order-1 max-md:p-3"}
            onValuesChange={(_, v) => {
              setValues2(v.unStake);
              setValues1({
                input:
                  v.unStake.input && willReceiveRate
                    ? parseFloat(
                        Number(
                          formatFixed(
                            parseFixed(v.unStake.input, 18).mul(
                              willReceiveRate
                            ),
                            36
                          )
                        ).toFixed(8)
                      ).toString()
                    : undefined,
              });
              setunStakeMax(false);
              setValueinModal2(v.unStake?.input);
              setValueinModal1(
                v.unStake.input && willReceiveRate
                  ? formatFixed(
                      parseFixed(v.unStake.input, 18).mul(willReceiveRate),
                      36
                    ).toString()
                  : undefined
              );
            }}
          >
            <p className="gray pb-2">{status ? "Send" : "Receive"}</p>
            <FormItem
              className="unStake"
              field="unStake"
              rules={[
                {
                  validator: (val, cb) => {
                    // console.log(val);
                    if (val.input && parseFixed(val.input, 18).eq(0)) {
                      cb("Amount is required");
                    }
                    if (
                      val.input &&
                      saETH_balance &&
                      parseFixed(val.input, 18).gt(saETH_balance)
                    ) {
                      cb("saETH Insufficient liquidity");
                    }
                    // cb();
                  },
                },
              ]}
              extra={
                chainId &&
                  <div className="flex justify-between items-center w-full">
                  {status ?
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      saETH_balance &&
                        setValues2({
                          ...values2,
                          input: parseFloat(
                            formateNormalNumber(formatEther(saETH_balance), 8)
                          ).toString(),
                        });
                      setValues1({
                        ...values1,
                        input:
                          saETH_balance && willReceiveRate
                            ? parseFloat(
                                Number(
                                  formatFixed(
                                    saETH_balance.mul(willReceiveRate),
                                    36
                                  )
                                ).toFixed(8)
                              ).toString()
                            : undefined,
                      });
                      setunStakeMax(true);
                      setValueinModal2(
                        formatEther(saETH_balance ?? 0).toString()
                      );
                      setValueinModal1(
                        saETH_balance && willReceiveRate
                          ? formatFixed(
                              saETH_balance.mul(willReceiveRate),
                              36
                            ).toString()
                          : undefined
                      );
                    }}
                  >
                    Max:{" "}
                    {saETH_balance
                      ? formatNumber(formatEther(saETH_balance), 4)
                      : "-"}
                  </span>
                  :<span></span>
                }
                {/* {values2.input && <span>{parseFixed(Number(values2.input).toFixed(8).toString(),18).toString()}</span>} */}
                  {values2.input && context.Data?.saETHPrice && <span>≈&nbsp;{formatNumber(formatFixed(parseFixed(Number(values2.input).toFixed(8).toString(), 18).mul(parseFixed(context.Data?.saETHPrice,18)).div(BigNumber.from(10).pow(18)),18), 2)}</span>}
                </div>
              }
              wrapperCol={{ span: 24 }}
              initialValue={values2}
            >
              <CustomUnStakeInput status={status} val={values2} />
            </FormItem>
            {/* {JSON.stringify(values2, null, 2)} */}
          </Form>
        </div>
        {status ? (
          <div className="gap-0">
            <Row className="flex align-middle leading-8 text-sm">
              <Col className="gray" span={12}>
                Exchange rate
              </Col>
              <Col span={12} className="text-end">
                1saETH ={" "}
                {willReceiveRate
                  ? parseFloat(
                      formatNumber(formatFixed(willReceiveRate, 18), 4)
                    )
                  : "-"}{" "}
                aETH
              </Col>
            </Row>
            <Row className="flex align-middle leading-8">
              <Col className="gray" span={12}>
                Max transaction cost
              </Col>
              <Col span={12} className="text-end">
                {chainId && supportchain && maxConst
                  ? "$" + formatNumber(formatFixed(maxConst, 26), 4)
                  : "-"}
              </Col>
            </Row>
            <Row className="flex align-middle leading-8 text-sm">
              <Col className="gray" span={12}>
                You will receive
              </Col>
              <Col span={12} className="text-end">
                {values1.input ? formatNumber(values1.input, 8) : "-"} aETH
              </Col>
            </Row>
          </div>
        ) : (
          <div className="gap-0 text-sm max-md:text-xs">
            <Row className="flex align-middle leading-8">
              <Col className="grayNoFont" span={12}>
                Exchange rate
              </Col>
              <Col span={12} className="text-end">
                1aETH ={" "}
                {willReceiveRate
                  ? parseFloat(
                      formatNumber(formatFixed(willReceiveRate, 18), 4)
                    )
                  : "-"}{" "}
                saETH
              </Col>
            </Row>
            <Row className="flex align-middle leading-8">
                <Col className="grayNoFont" span={12}>Max transaction cost</Col>
                <Col span={12} className="text-end">
                { (chainId && supportchain && maxConst) ? '$' + formatNumber(formatFixed(maxConst, 26), 4) : "-"}
                </Col>
              </Row>
            <Row className="flex align-middle leading-8">
              <Col className="grayNoFont" span={12}>
                You will receive
              </Col>
              <Col span={12} className="text-end">
                {values2.input ? formatNumber(values2.input, 8) : "-"} saETH
              </Col>
            </Row>
          </div>
        )}
        {chainId && supportchain ? (
          status ? (
            <>
              <div
                className={classnames(
                  "w-full py-4 text-base text-center rounded-xl cursor-pointer btnBg",
                  { disabledNoGray: !values2.input, disabled: staking }
                )}
                onClick={OnUnStake}
              >
                Unstake
              </div>
              <Link to="/Withdrawal">
                <div className="w-full py-4 text-base text-center rounded-xl cursor-pointer borderBg flex justify-center items-center">
                  <div className="fontLinear">Unstake & Withdrawal</div>
                  <IconArrowRight
                    className="ml-1"
                    style={{ color: "#FF3585" }}
                  />
                </div>
              </Link>
            </>
          ) : !aETH_allowance ||
            aETH_allowance?.eq("0") ||
            (values1.input &&
              aETH_allowance.lt(parseFixed(values1.input, 18))) ? (
            <div
              className={classnames(
                "w-full py-4 text-base text-center rounded-xl cursor-pointer btnBg",
                { disabled: approving }
              )}
              onClick={OnApprove}
            >
              Approve
            </div>
          ) : (
            <div
              className={classnames(
                "w-full py-4 text-base text-center rounded-xl cursor-pointer btnBg",
                { disabledNoGray: !values1.input, disabled: staking }
              )}
              onClick={OnStake}
            >
              Stake
            </div>
          )
        ) : (
          <div
            className="w-full py-4 text-base text-center rounded-xl cursor-pointer btnBg"
            onClick={() => setVisible(true)}
          >
            Connect wallet
          </div>
        )}
      </Typography>
      <Typography className="p-6 pt-1 sectionBg mt-6 _shadowSmall fontColor text-sm max-md:p-4 max-md:text-xs max-md:mt-4">
        <Title className="fontColor" heading={5}>Aspida statistics</Title>
        <Row className="flex align-middle leading-8">
          <Col className="grayNoFont" span={12}>
            Total aETH staked
          </Col>
          {/* <Col span={12} className="text-end">{(chainId && totalaETHStaked) ? formatNumber(formatFixed(totalaETHStaked, 18), 4) : '-'} aETH</Col> */}
          <Col span={12} className="text-end">
            {context.Data?.totalStakedTokens
              ? formatNumber(
                  formatFixed(context.Data?.totalStakedTokens, 18),
                  4
                )
              : "-"}{" "}
            aETH
          </Col>
        </Row>
        <Row className="flex align-middle leading-8">
          <Col className="grayNoFont" span={12}>
            saETH market cap
          </Col>
          {/* <Col span={12} className="text-end">{(chainId && saETHMarketCap && context.ETHFeed) ? '$'+formatNumber(formatFixed(saETHMarketCap, (18 + Number(context.ETHFeed.decimals))), 4) : '-'}</Col> */}
          <Col span={12} className="text-end">
            {context.Data?.totalStakedTokensAmount
              ? `$ ${formatNumber(context.Data?.totalStakedTokensAmount, 4)}`
              : "-"}
          </Col>
        </Row>
        <Row className="flex align-middle leading-8">
          <Col className="grayNoFont" span={12}>
            Annual percentage rate
          </Col>
          <Col span={12} className="text-end">
            {context.Data?.apr ? context.Data?.apr : "-"}
          </Col>
        </Row>
        <Row className="flex align-middle leading-8">
          <Col className="grayNoFont" span={12}>
            Stakers
          </Col>
          <Col span={12} className="text-end">
            {context.Data?.stakers ? context.Data?.stakers : "-"}
          </Col>
        </Row>
      </Typography>
      {/* stake Modal */}
      <Modal
        // title={
        //   <Typography className="text-left">
        //   </Typography>
        // }
        visible={stakevisible}
        onOk={() => setStakevisible(false)}
        onCancel={() => setStakevisible(false)}
        autoFocus={false}
        maskClosable={false}
        footer={null}
        // closeIcon={<img src={Close} />}
      >
        {
          <div className="flex flex-col justify-center items-center mt-6 gap-4 max-md:gap-3">
            <div
              className={classnames(
                "IconSyncStake flex  flex-row justify-center items-center cursor-pointer",
                {
                  pendingBg: stakeTransactionStatus === 0,
                  successBg: stakeTransactionStatus === 1,
                  failBg: (stakeTransactionStatus === 2) || (stakeTransactionStatus === 3),
                }
              )}
              style={{ color: "#fff" }}
            >
              {stakeTransactionStatus === 0 ? (
                // <img src={sync} className="rotate" />
                <div className="rotate">
                  <IconResearch width="60" height="60"/>
                </div>
              ) : stakeTransactionStatus === 1 ? (
                // <img src={success} />
                <IconDone width="60" height="60"/>
              ) : (
                <IconFail width="60" height="60"/>
                // <img src={fail} />
              )}
            </div>
            <div className="font26 max-md:text-base">
            {stakeTransactionStatus === 0 ? (
              <span>
                Staking {formatNumber(parseFloat(valueinModal2), 4)} saETH
              </span>
            ) : stakeTransactionStatus === 1 ? (
              <span>
                Successfully staked {formatNumber(parseFloat(valueinModal2), 4)}{" "}
                saETH
              </span>
            ) : stakeTransactionStatus === 2 ? (
              <span>{errorTxt?.reason ?? errorTxt}</span>
            )
            :(
              <span>{errorTxt}</span>
            )
          }
          </div>
            {stakeTransactionStatus === 0 && (
              <div className="_flex flex-row justify-center items-center gap-2 text-sm max-md:text-xs">
                <div className="flex items-center">
                  <img className="inline-block mr-1" width={20} src={aETH} />
                  <span className="mr-1">
                    {formatNumber(parseFloat(valueinModal1), 4)}
                  </span>
                  <span>aETH</span>
                </div>
                <div className="IconArrowRight flex justify-center items-center">
                  {/* <IconArrowRight /> */}
                  <IconDarkAArriveB />
                </div>
                <div className="flex items-center">
                  <img className="inline-block mr-1" width={20} src={saETH} />
                  <span className="mr-1">
                    {formatNumber(parseFloat(valueinModal2), 4)}
                  </span>
                  <span>saETH</span>
                </div>
              </div>
            )}
            <div className="flex flex-col items-center grayNoFont font20 pt-5 max-md:text-xs max-md:pt-3">
            {stakeTransactionStatus === 0 ? (
              <div>
                Confirm this transaction in your wallet
              </div>
            ) : stakeTransactionStatus == 1 ? (
              <div>Earn rewards by staking aETH</div>
            ) : stakeTransactionStatus == 2 ? (
              <div>{errorTxt?.reason ?? errorTxt}</div>
            ):(
              <div>Please re-initiate the request</div>
            )
          }
            {((stakeTransactionStatus === 1) || (stakeTransactionStatus === 3)) && (
              <a
                href={`${(TX_LINK as any)[chainId as number]}${hash}`}
                target="_blank"
                rel="noreferrer"
                className="flex items-center ml-3"
                style={{ color: "#3B82F6"}}
              >
                {/* <IconLaunch /> */}
                <span className="mr-1">View on Etherscan</span>
                <IconExternalLink />
              </a>
            )}
            </div>
          </div>
        }
      </Modal>

      {/* unStake Modal */}
      <Modal
        // title={
        //   <Typography className="text-left">
        //   </Typography>
        // }
        visible={unstakevisible}
        onOk={() => setUnstakevisible(false)}
        onCancel={() => setUnstakevisible(false)}
        autoFocus={false}
        maskClosable={false}
        footer={null}
        // closeIcon={<img src={Close} />}
      >
        {
          <div className="flex flex-col justify-center items-center mt-6 gap-4">
            <div
              className={classnames(
                "IconSyncStake flex  flex-row justify-center items-center cursor-pointer",
                {
                  pendingBg: unStakeTransactionStatus === 0,
                  successBg: unStakeTransactionStatus === 1,
                  failBg: (unStakeTransactionStatus === 2) || (unStakeTransactionStatus === 3),
                }
              )}
              style={{ color: "#fff" }}
            >
              {unStakeTransactionStatus === 0 ? (
                // <img src={sync} className="rotate" />
                <div className="rotate">
                  <IconResearch width="60" height="60"/>
                </div>
              ) : unStakeTransactionStatus === 1 ? (
                // <img src={success} />
                <IconDone width="60" height="60"/>
              ) : (
                <IconFail width="60" height="60"/>
                // <img src={fail} />
              )}
            </div>
            <div className="font26 max-md:text-base">
            {unStakeTransactionStatus === 0 ? (
              <span>
                UnStake {formatNumber(parseFloat(valueinModal1), 4)} aETH
              </span>
            ) : unStakeTransactionStatus === 1 ? (
              <span>
                Unstaked{" "}
                {formatNumber(parseFloat(valueinModal2), 4)} saETH
              </span>
            ) : unStakeTransactionStatus === 2 ? (
              <span>{errorTxt?.reason ?? errorTxt}</span>
            ):
            (
              <span>{errorTxt}</span>
            )
          }
          </div>
            {unStakeTransactionStatus === 0 && (
              <div className="_flex flex-row justify-center items-center gap-2 text-sm max-md:text-xs">
                <div className="flex items-center">
                  <img className="inline-block mr-1" width={20} src={saETH} />
                  <span className="mr-1">
                    {formatNumber(parseFloat(valueinModal2), 4)}
                  </span>
                  <span>saETH</span>
                </div>
                <div className="IconArrowRight flex justify-center items-center">
                  {/* <IconArrowRight /> */}
                  <IconDarkAArriveB />
                </div>
                <div className="flex items-center">
                  <img className="inline-block mr-1" width={20} src={aETH} />
                  <span className="mr-1">
                    {formatNumber(parseFloat(valueinModal1), 4)}
                  </span>
                  <span>aETH</span>
                </div>
              </div>
            )}
            <div className="flex flex-col items-center grayNoFont font20 pt-5 max-md:text-xs max-md:pt-3">
            {unStakeTransactionStatus === 0 ? (
              <div >
                Confirm this transaction in your wallet
              </div>
            ) : unStakeTransactionStatus == 1 ? (
              <div>Earn rewards by staking aETH</div>
            ) : unStakeTransactionStatus == 1 ? (
              <div>{errorTxt?.reason ?? errorTxt}</div>
            ):(
              <div>Please re-initiate the request</div>
            )
          }
            {((unStakeTransactionStatus === 1) || (unStakeTransactionStatus === 3)) && (
              <a
                href={`${(TX_LINK as any)[chainId as number]}${hash}`}
                target="_blank"
                rel="noreferrer"
                className="flex items-center ml-3"
                style={{ color: "#3B82F6", fontSize: "20px" }}
              >
                {/* <IconLaunch /> */}
                <span className="mr-1">View on Etherscan</span>
                <IconExternalLink />
              </a>
            )}
            </div>
          </div>
        }
      </Modal>
    </div>
  );
};

export default Stake;
