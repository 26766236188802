import { useCallback, useEffect, useState } from 'react'
import type { Web3ReactHooks } from '@web3-react/core'
import { Contract } from '@ethersproject/contracts'
import type { BigNumber } from '@ethersproject/bignumber'
const useBalance = (
    provider?: ReturnType<Web3ReactHooks['useProvider']> | Contract,
    account?: string,
    isSupportchain?: boolean,
    refresh: number = 0,
    IntervalTime: number = 10,
): BigNumber | undefined => {
    const [balance, setBalance] = useState<BigNumber | undefined>()

    const getBalance = useCallback(() => {
        if (provider && account && isSupportchain) {
            let stale = false

            // void Promise.all(account.map((account) => provider.getBalance(account))).then((balances) => {
            //   console.log(balances)
            //   if (stale) return
            //   setBalance(balances)
            // })

            provider.getBalance(account).then((balance: (BigNumber | undefined)) => {
                // console.log(balance)
                if (stale) return
                setBalance(balance)
            })


            return () => {
                stale = true
                setBalance(undefined)
            }
        }
    }, [provider, account, isSupportchain])

    useEffect(() => {
        getBalance()
        let refreshInterval = setInterval(() => {
            getBalance()
        }, 1000 * IntervalTime)
        return () => clearInterval(refreshInterval)
    }, [provider, account, isSupportchain, refresh])

    return balance
}
export default useBalance