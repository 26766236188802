import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
  ReactNode,
} from "react";
import { Context } from "../App";
import {
  useWeb3React,
  Web3ReactHooks,
  Web3ReactProvider,
} from "@web3-react/core";
import type { Provider } from "@web3-react/types";
import { ethers } from "ethers";
import {
  Tabs,
  Typography,
  Grid,
  Tooltip,
  Button,
  TabsProps,
  Card,
  Avatar,
  Divider,
  Tag,
  Form,
  Input,
  Select,
  Image,
  Link,
  Message,
  Modal,
} from "@arco-design/web-react";
const TabPane = Tabs.TabPane;
const { Title, Paragraph, Text } = Typography;
const Row = Grid.Row;
const Col = Grid.Col;
const ButtonGroup = Button.Group;
const { Grid: _Grid } = Card;
const AvatarGroup = Avatar.Group;
import {
  IconCheckCircle,
  IconClockCircle,
  IconMoreVertical,
  IconPlusCircleFill,
  IconLaunch,
  IconSync,
  IconCheck,
  IconClose,
  IconArrowRight,
} from "@arco-design/web-react/icon";
import { IconAddToken, IconDone, IconExternalLink, IconFail, IconPendingTime, IconReadyToCliam, IconResearch } from "@arco-iconbox/react-lsd";
import Aspida from "../assets/img/Aspida_select.svg";
import Curve from "../assets/img/Curve.svg";
import uniswap from "../assets/img/uniswap.svg";
import { AddTokenToMetamask, _initContract, formatNumber, formatNumberNotCarry, formateNormalNumber, initContract, multicall, multicall_target } from "../utils";
import _InputWithdrawal from "./_InputWithdrawal";
import TabHeader from "@arco-design/web-react/es/Tabs/tab-header";
import useBalance from "../hooks/useBalance";
import useBalanceETH from "../hooks/useBalanceETH";
import CorePrimaryConfig from "../contracts/CorePrimary.json";
import aETHConfig from "../contracts/aETH.json";
import saETHConfig from "../contracts/saETH.json";
import aETH from "../assets/img/token/aETH.svg";
import saETH from "../assets/img/token/saETH.svg";
import sync from "../assets/img/sync.svg";
import successIcon from "../assets/img/success.svg";
import fail from "../assets/img/fail.svg";
import waiting from "../assets/img/waiting.svg"
import Close from "../assets/img/Close.svg"
import { parseFixed, formatFixed, BigNumber } from "@ethersproject/bignumber";
import { formatEther } from "@ethersproject/units";
import useAllowance from "../hooks/useAllowance";
import {GAS, TX_LINK} from "../constants/index"
import classnames from "classnames";
import { CHAINS, getAddChainParameters } from "../chains";


interface CustomInputProps {
  options: { label: ReactNode | Element | string; value: string | number; disabled?: boolean; extra?: any }[],
  val: Record<string, string>;
}
const img: Record<string, string> = {
  saETH: saETH,
  aETH: aETH,
};
const CustomInput: React.FC<CustomInputProps> = (props: any) => {
  const value = props.value || {};
  const val = props.val || {};
  // console.log(value);
  const handleChange = (newValue: any) => {
    props.onChange && props.onChange(newValue);
  };

  return (
    <div className="flex w-full">
      <Input
        value={val.input}
        placeholder="0.0"
        onChange={(v) => {
          handleChange({ ...value, input: v.trim().replace(".", "$#$").replace(/\./g, "").replace("$#$", ".").replace(/[^\d.]/g, "").replace(/^\./g, "").replace(/^(\-)*(\d+)\.(\d\d\d\d\d\d\d\d).*$/, "$1$2.$3") });
        }}
        addBefore={
          <Select // select component has defined error style
            error={props.error}
            placeholder="Please select ..."
            style={{ minWidth: 70 }}
            className="inputBg"
            value={val.select}
            options={props.options}
            onChange={(v) => {
              // handleChange({ ...value, select: v });
              handleChange({ input:undefined, select: v });
            }}
          />
        }
        beforeStyle={{ border: 0 }}
      />
    </div>
  );
};
interface WithdrwalProps {
  setVisible: (visible: boolean) => void;
}
const Withdrwal: React.FC<WithdrwalProps> = ({ setVisible }) => {
  const context = useContext(Context);
  const { provider, connector, account, chainId } = useWeb3React();
  const supportchain = chainId ? Object.keys(CorePrimaryConfig.networks).includes((chainId as number).toString()) : false;

  const [form_aETH] = Form.useForm();
  const [withdrwalvisible, setWithdrwalvisible] = useState(false);
  // 0:初始状态 1: success 2: fail 3:revert
  const [withdrwalTransactionStatus, setWithdrwalTransactionStatus] = useState<
    number
  >(0);
  // 0：排队状态 1： success
  const [success, setSuccess] = useState<number>(2)

  const [claimvisible, setClaimvisible] = useState(false);
  // 0:初始状态 1: success 2: fail
  const [claimTransactionStatus, setClaimTransactionStatus] = useState<
    number
  >(0);
  const [errorTxt, setErrorTxt] = useState<any>("");
  const [hash, setHash] = useState<string | undefined>(undefined)

  const [refreshAllowance, setrefreshAllowance] = useState<number>(0);
  const aETH_allowance = useAllowance(aETHConfig, CorePrimaryConfig, refreshAllowance);
  const saETH_allowance = useAllowance(saETHConfig, CorePrimaryConfig, refreshAllowance);
  const [approving, setApproving] = useState<boolean>(false);
  const [claiming, setClaiming] = useState<boolean>(false);
  const [withdrawling, setWithdrawling] = useState<boolean>(false);

  const [refreshBalance, setrefreshBalance] = useState<number>(0)
  const aETH_balance = useBalance(provider, aETHConfig, account, chainId,supportchain,refreshBalance);
  const saETH_balance = useBalance(provider, saETHConfig, account, chainId,supportchain,refreshBalance);
  const [withdrwalMax, setWithdrwalMax] = useState<boolean>(false);
  const [data, setData] = useState<Record<string, string> | undefined>(undefined)
  const [waitingTime,setWaitingTime] = useState<string | undefined>(undefined)
  const [claimJSON, setClaimJSON] = useState({ data: [], AvailableClaim: 0, PendingClaim: 0 })
  const [hasClaim,setHasClaim] = useState<BigNumber | number>(0)
  const options: string[] = ["aETH", "saETH"];
  const [values, setValues] = useState<any>({
    select: "aETH",
    input: undefined,
  });
  const [valueinModal, setValueinModal] = useState<any>(undefined);
  const [disabledBtn, setDisabledBtn] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState("1");
  const [type, setType] = useState<string>("1");
  const Select = useCallback((type: string) => {
    setType(type);
  }, []);

  const [willReceiveRate,setWillReceiveRate] = useState<BigNumber | undefined>(undefined)

  const _willReceiveRate =  useMemo(() => {
    if(chainId && account && provider && supportchain){
      if(values.select === 'aETH'){
        return BigNumber.from(10).pow(18)
      }else{
        const saETH = initContract((saETHConfig.networks as any)?.[chainId.toString()]?.address, saETHConfig.abi,provider)
          const saETH_ETH = saETH.convertToAssets(BigNumber.from(10).pow(18));
          return saETH_ETH;
          // return BigNumber.from(10).pow(18).mul(2)
      }
    }
    
  }, [values.select,chainId,account,provider,supportchain])
  const getReceive = useCallback(async()=>{
    setWillReceiveRate(await _willReceiveRate)
    // console.log("_willReceiveRate",(await _willReceiveRate)?.toString())
  },[_willReceiveRate])
  useEffect(()=>{
    if(supportchain){
      getReceive()
    }
    
  },[values,supportchain])
  const OnaETHApprove = useCallback(async () => {
    if (!account || !chainId || !window.ethereum) return;
    setApproving(true);
    const aETH = _initContract(
      (aETHConfig.networks as any)?.[chainId.toString()]?.address,
      aETHConfig.abi
    );

    const _provider = new ethers.providers.Web3Provider(window?.ethereum);
    const signer = _provider.getSigner(account);
    try {
      const receipt = await aETH
        .connect(signer)
        .approve(
          (CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address,
          ethers.constants.MaxUint256
        );
      // console.log("receipt: ", receipt);
      setApproving(false)
        setrefreshAllowance(Math.random());
      // receipt.getTransaction((hash: string) => {
      //   console.log("transactionHash: ", hash);
      // })
      // receipt.getTransactionReceipt()((receipt: any) => {
      //   console.log("receipt: ", receipt);
      //   const refreshId = setTimeout(() => {
      //     setrefreshAllowance(Math.random())
      //     clearTimeout(refreshId)
      //   }, 500)
      // })
    } catch (error) {
      setApproving(false);
      console.log("error", error);
    }
  }, [account, chainId]);
  const OnsaETHApprove = useCallback(async () => {
    if (!account || !chainId || !window.ethereum) return;
    setApproving(true);
    const saETH = _initContract(
      (saETHConfig.networks as any)?.[chainId.toString()]?.address,
      saETHConfig.abi
    );

    const _provider = new ethers.providers.Web3Provider(window?.ethereum);
    const signer = _provider.getSigner(account);
    try {
      const receipt = await saETH
        .connect(signer)
        .approve(
          (CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address,
          ethers.constants.MaxUint256
        );
      // console.log("receipt: ", receipt);
      setApproving(false)
        setrefreshAllowance(Math.random());
      // receipt.getTransaction((hash: string) => {
      //   console.log("transactionHash: ", hash);
      // })
      // receipt.getTransactionReceipt()((receipt: any) => {
      //   console.log("receipt: ", receipt);
      //   const refreshId = setTimeout(() => {
      //     setrefreshAllowance(Math.random())
      //     clearTimeout(refreshId)
      //   }, 500)
      // })
    } catch (error) {
      setApproving(false);
      console.log("error", error);
    }
  }, [account, chainId]);
  // 0xd2655ea88b9495361b999d5c84f604d4515da4bf3a034bde9721e3d128fceac0,
  const OnaETH_withdrawal = useCallback(async () => {
    if (form_aETH && chainId && account && data && window?.ethereum) {
      try {
        if (!values.input) return;
        // if (parseFixed(values.input, 18).lt(data.withdrawThreshold) || parseFixed(values.input, 18).gt(data.withdrawRemaining)) return;
        await form_aETH.validate();
        setWithdrawling(true)
        const CorePrimary = _initContract(
          (CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address,
          CorePrimaryConfig.abi
        );
        const _provider = new ethers.providers.Web3Provider(window?.ethereum);
        const signer = _provider.getSigner(account);
        // console.log(
        //   CorePrimary.connect(signer),
        //   signer,
        //   parseFixed(values.input, 18)
        // );
        try {
          setWithdrwalvisible(true);
          const receipt = await CorePrimary.connect(signer)["withdraw(uint256)"](
            withdrwalMax ? aETH_balance : parseFixed(values.input, 18),
          )
          setWithdrawling(false)
          // 查询是否需要排队
          const re = await receipt.wait()
          // console.log("re",re)
          if(re.status === 1){
            const result = await _provider.getTransactionReceipt(receipt.hash)
            setValues({...values,input:undefined})
            const success = result?.logs.filter(
              (item: any) => item.address === (CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address
            ).map((it: any) => {
              return it.topics[0]
            }).includes("0xd1c19fbcd4551a5edfb66d43d2e337c04837afda3482b42bdf569a8fccdae5fb");
            if (success) {
              setSuccess(1)
              setWithdrwalTransactionStatus(1);
              setrefreshBalance(Math.random())
            } else {
              setSuccess(0)
            }
          }else{
            setWithdrawling(false)
            setErrorTxt("Failed to send request withdrawal");
            setSuccess(3)
            setWithdrwalTransactionStatus(3);
          }
          setHash(receipt.hash)
          // CorePrimary.once("transactionHash", (receipt: { hash: string; }) => {
          //   console.log('Transaction Minded: ' + receipt.hash);
          //   console.log(receipt);
          // });
          // receipt.getTransaction((hash: string) => {
          //   console.log("transactionHash: ", hash);
          // });
          // receipt.getTransactionReceipt()((receipt: any) => {
          //   console.log("receipt: ", receipt);
          // });
        } catch (error) {
          console.log("error", error);
          setWithdrawling(false)
          setErrorTxt("user rejected transaction")
          setWithdrwalTransactionStatus(2);
        }
        // Message.info('校验通过，提交成功！');
      } catch (_) {
        // console.log(form_aETH.getFieldsError());
        setWithdrawling(false)
        // Message.error("校验失败，请检查字段！");
      }
    }
  }, [values, withdrwalMax, aETH_balance, data, chainId, account, window?.ethereum]);
  const OnsaETH_withdrawal = useCallback(async () => {
    if (form_aETH && data && chainId && account && provider && window?.ethereum) {
      try {
        if (!values.input) return;
        await form_aETH.validate();
        setWithdrawling(true)
        setHash(undefined);
        setErrorTxt("")
        setWithdrwalTransactionStatus(0)
        setSuccess(2)
        // const saETH = initContract((saETHConfig.networks as any)?.[chainId.toString()]?.address, saETHConfig.abi, provider);
        // const previewRedeem_aETH = await saETH.previewRedeem(withdrwalMax ? saETH_balance : parseFixed(values.input, 18));
        // if (previewRedeem_aETH.lt(data.withdrawThreshold) || parseFixed(values.input, 18).gt(data.withdrawRemaining)) return;
        const CorePrimary = _initContract(
          (CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address,
          CorePrimaryConfig.abi
        );
        const _provider = new ethers.providers.Web3Provider(window?.ethereum);
        const signer = _provider.getSigner(account);
        try {
          setWithdrwalvisible(true);
          const receipt = await CorePrimary.connect(signer).redeemAndWithdraw(
            withdrwalMax ? saETH_balance : parseFixed(values.input, 18),
          );
          setWithdrawling(false)
          setValues({...values,input:undefined})
          const re = await receipt.wait()
          // console.log("re",re)
          // 查询是否需要排队
          if(re.status === 1){
            const result = await _provider.getTransactionReceipt(receipt.hash)
            const success = result?.logs.filter(
              (item: any) => item.address === (CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address
            ).map((it: any) => {
              return it.topics[0]
            }).includes("0xd1c19fbcd4551a5edfb66d43d2e337c04837afda3482b42bdf569a8fccdae5fb");
            if (success) {
              // setHash(receipt.hash)
              setSuccess(1)
              setWithdrwalTransactionStatus(1);
              setrefreshBalance(Math.random())
            } else {
              setSuccess(0)
            }
          }else{
            setWithdrawling(false)
            setErrorTxt("Failed to send request withdrawal");
            setSuccess(3)
            setWithdrwalTransactionStatus(3);
          }
          setHash(receipt.hash)
        } catch (error) {
          console.log("error", error);
          setWithdrawling(false)
          setErrorTxt("user rejected transaction")
          setWithdrwalTransactionStatus(2);
        }
        // Message.info('校验通过，提交成功！');
      } catch (_) {
        // console.log(form_aETH.getFieldsError());
        setWithdrawling(false)
        // Message.error("校验失败，请检查字段！");
      }
    }
  }, [values, withdrwalMax, saETH_balance, data, chainId, account, provider, window?.ethereum]);
  const OnClaim = useCallback(async () => {
    if (chainId && account && window?.ethereum) {
      try {
        const CorePrimary = _initContract((CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address, CorePrimaryConfig.abi)
        const _provider = new ethers.providers.Web3Provider(window?.ethereum)
        const signer = _provider.getSigner(account)
        try {
          setClaiming(true)
          setClaimvisible(true)
          setHash(undefined);
          setErrorTxt("")
          setClaimTransactionStatus(0)
          const receipt = await CorePrimary.connect(signer)
          ["claim()"]();
          const re = await receipt.wait()
          // console.log("re",re)
          if(re.status === 1){
            setClaimTransactionStatus(1)
          }else{
            setClaimTransactionStatus(3)
          }
            setHash(receipt.hash)
            setrefreshBalance(Math.random())
            setClaiming(false)
            await FetchData()
        } catch (error) {
          console.log("error", error)
          setErrorTxt("user rejected transaction")
          setClaimTransactionStatus(2)
          setClaiming(false)
        }
        // Message.info('校验通过，提交成功！');
      } catch (error) {
        setClaiming(false)
        console.log(error);
      }
    }
  }, [chainId, account, window?.ethereum, claimJSON])
  const FetchData = useCallback(async () => {
    if (provider && chainId && account && window.ethereum && willReceiveRate) {
      try {
      const rpcUrl = CHAINS[chainId]?.urls[Math.floor(Math.random()*CHAINS[chainId]?.urls.length)]
      // // const _provider = new ethers.providers.Web3Provider(window?.ethereum)
      const multical_params = [
        // {
        //   target:(CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address,
        //   call:["withdrawThreshold()(uint256)"],
        //   key:"withdrawThreshold"
        // },
        // {
        //   target:(CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address,
        //   call:["withdrawRemaining()(uint256)"],
        //   key:"withdrawRemaining"
        // },
        {
          target:(CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address,
          call:["withdrawableAmount()(uint256)"],
          key:"withdrawableAmount"
        },
        {
          target:(CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address,
          call:["claimDataByAddress(address)(uint256[],uint256[],bool[])",account],
          returns: [
            ["queueId", (val: any) => val],
            ["ClaimData", (val: any) => val],
            ["availableStatus",(val:boolean)=>val]
          ],
          key:"claimDataByAddress"
        },
        {
          target:(CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address,
          call:["claimableAmount()(uint256)"],
          key:"claimableAmount"
        }
        // {
        //   target:(saETHConfig.networks as any)?.[chainId.toString()]?.address,
        //   call:["rewardRate()(uint256)"],
        //   key:"saETHRate"
        // },
        // {
        //   target:(saETHConfig.networks as any)?.[chainId.toString()]?.address,
        //   call:["totalAssets()(uint256)"],
        //   key:"totalAssets"
        // }
        // {
        //   target:(CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address,
        //   call:['allowance(address,address)(uint256)',account,lending?.contracts?.lsr?.liqudateAddressMap?.lsr[SendToken.symbol]],
        //   key:'isAllowance'
        // }
      ]
      const res = await multicall(rpcUrl,multicall_target[chainId], multical_params)
      if(!res) return;
      // const saETHdailyLimit = willReceiveRate.add(res.results.transformed.saETHRate.mul('300').div(res.results.transformed.totalAssets)).toString()
      
      // console.log(res)
      // try {
      //   const CorePrimary = initContract((CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address, CorePrimaryConfig.abi, provider);
      //   const saETH = initContract((saETHConfig.networks as any)?.[chainId.toString()]?.address, saETHConfig.abi, provider);
      //   const withdrawableAmount = await CorePrimary.withdrawableAmount()
      //   const saETHWithdrawableAmount = await saETH.convertToShares(withdrawableAmount)
      //   const withdrawThreshold = await CorePrimary.withdrawThreshold()
      //   const withdrawRemaining = await CorePrimary.withdrawRemaining()
      //   const dailyLimit = await CorePrimary.withdrawRemaining()
      //   const saETHRate = await saETH.rewardRate()
      //   const totalAssets = await saETH.totalAssets()
      //   const saETHdailyLimit = willReceiveRate.add(saETHRate.mul('300').div(totalAssets)).toString()
        // saETH amount * (previewRedeem(1)* (rate*300)/totalAssets)
        const multical_params_2 = [
          {
            target:(saETHConfig.networks as any)?.[chainId.toString()]?.address,
            call:["convertToShares(uint256)(uint256)",res.results.transformed.withdrawableAmount],
            key:"saETHWithdrawableAmount"
          }
        ]
        const res_2 = await multicall(rpcUrl,multicall_target[chainId], multical_params_2)
        if(!res_2) return;

        
        setData({
          withdrawableAmount:res.results.transformed.withdrawableAmount,
          saETHWithdrawableAmount:res_2.results.transformed.saETHWithdrawableAmount,
          // withdrawThreshold:res.results.transformed.withdrawThreshold,
          // withdrawRemaining:res.results.transformed.withdrawRemaining,
          // dailyLimit:res.results.transformed.withdrawRemaining,
          // saETHdailyLimit
        })
        
        // const CorePrimary = initContract((CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address, CorePrimaryConfig.abi, provider);
        // const { 0: queueId, 1: ClaimData } = await CorePrimary.userQueueIds(account)
        // const claimableAmount = await CorePrimary.claimableAmount()
        let _claimJSON = res.results.transformed.queueId.map((id: any, index: number) => {
          return {
            key: id,
            amount: res.results.transformed.ClaimData[index],
            availableStatus: res.results.transformed.availableStatus[index]
          }
        })
        const AvailableClaim = _claimJSON.filter((item: any) => item.availableStatus).reduce((prev: any, curr: any) => {
          return {
            amount: BigNumber.from(prev.amount).add(BigNumber.from(curr.amount))
          }
        }, { amount: BigNumber.from('0') })
        setHasClaim(AvailableClaim.amount)
        const PendingClaim = _claimJSON.filter((item: any) => !item.availableStatus).reduce((prev: any, curr: any) => {
          // console.log(prev.amount, curr.amount)
          return {
            amount: BigNumber.from(prev.amount).add(BigNumber.from(curr.amount))
          }
        }, { amount: BigNumber.from('0') })
        _claimJSON = { data: _claimJSON, AvailableClaim: AvailableClaim.amount, PendingClaim: PendingClaim.amount }
        // console.log(JSON.stringify(_claimJSON, null, 2))
        // _claimJSON = {data:
        //   [{
        //   key:0,
        //   amount:'30000232323232323232323',
        //   availableStatus:'0',
        //   },{
        //     key:1,
        //     amount:'30000232323232323232323'
        //     }],
        //   AvailableClaim:'2727388239283232323',
        //   PendingClaim:'88888888888888888'
        // }
        setClaimJSON(_claimJSON)
        
        setrefreshBalance(Math.random())
      } catch (error) {
        console.log(error)
      }
    }
  }, [provider, chainId, account,willReceiveRate])
  const FetchWaitingTime = async()=>{
      fetch("https://api.aspidanet.com/estimate_time/")
      .then(response => response.json())
      .catch(error => console.error('Error:', error))
      .then(response => {
        // console.log('Success:', response);
        setWaitingTime(response.estimated_total_time_days)
      });
    
  }
  useEffect(()=>{
    if(values.select === 'aETH'){
      // if(withdrwalMax && aETH_balance && data){
      //       if(aETH_balance?.gt(data.withdrawRemaining)){
      //         form_aETH.setFields({
      //           'customInput': {
      //             error: {
      //               message: `Daily max limit is ${data && formatNumber(formatFixed(data.withdrawRemaining, 18),4)} ETH`,
      //             },
      //           },
      //         });
      //         setDisabledBtn(true)
      //         return;
      //       } 
      //     }
          setDisabledBtn(false)
    }
    
  },[withdrwalMax,values.select,aETH_balance,data])
  useEffect(()=>{
    if(values.select === 'saETH'){
      // if(withdrwalMax && saETH_balance && data){
      //   if(saETH_balance?.gt(data.withdrawRemaining)){
      //     form_aETH.setFields({
      //       'customInput': {
      //         error: {
      //           message: `Daily max limit is ${data && formatNumber(formatFixed(data.withdrawRemaining, 18),4)} ETH`,
      //         },
      //       },
      //     });
      //     setDisabledBtn(true)
      //     return;
      //   }  
      // }
      setDisabledBtn(false)
    }
    
  },[withdrwalMax,values.select,saETH_balance,data])
  useEffect(() => {
    window.withdrawal && clearInterval(window.withdrawal)
    FetchWaitingTime()
    const target = () => {
      if (provider && chainId && account && supportchain && willReceiveRate) {
        getReceive()
        FetchData();
      }
      return target
    }
    window.withdrawal = setInterval(target(), 1000 * 15);
    return () => {
      clearInterval(window.withdrawal);
    };
  }, [provider, chainId, account,supportchain,willReceiveRate])
  useEffect(()=>{
    setWithdrwalvisible(false)
    setClaimvisible(false)
    setValues({
      select: "aETH",
      input: undefined,
    });
    setValueinModal(undefined)
    form_aETH.resetFields();
  },[chainId,account])
  useEffect(()=>{
    if(!withdrwalvisible || !claimvisible){
      setHash(undefined);
      setErrorTxt("")
      setWithdrwalTransactionStatus(0)
      setSuccess(2)
      setClaimTransactionStatus(0)
    }
  },[withdrwalvisible,claimvisible])
  const [maxConst,setMaxConst] = useState<string | undefined>(undefined)
  const FetchGas = useCallback(async (value:string = "10000000000000000") => {
    if (provider && chainId && account && window.ethereum && supportchain && context.ETHFeed) {
      try {
        // const CorePrimary = _initContract((CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address, CorePrimaryConfig.abi)
          const { gasPrice } = await provider.getFeeData()
          // const _provider = new ethers.providers.Web3Provider(window?.ethereum)
          // const signer = _provider.getSigner(account)
        if (values.select === "saETH"){
            // const gas = await CorePrimary.connect(signer).estimateGas.redeemAndWithdraw(value)
            const Cost = BigNumber.from(GAS.redeemAndWithdraw).mul(BigNumber.from(gasPrice)).mul(BigNumber.from(context.ETHFeed.price)).toString()
            setMaxConst(Cost)
        } else {
          // const gas = await CorePrimary.connect(signer).estimateGas["withdraw(uint256)"](value)
          const Cost = BigNumber.from(GAS.withdraw).mul(BigNumber.from(gasPrice)).mul(BigNumber.from(context.ETHFeed.price)).toString()
          setMaxConst(Cost)
        }
      } catch (error) {
        console.log(error)
        setMaxConst(undefined)
      }
    }
  }, [provider, chainId, account, supportchain, context.ETHFeed, values.select])
  useEffect(()=>{
    FetchGas()
  },[provider, chainId, account, supportchain, context.ETHFeed,values.select])
  return (
    // <div className="w-520">
      <div className="w-490 overflow-visible max-md:w-full">
      <div className="text-center">
        <div className="text-3xl font-medium mt-2 max-md:mt-4 max-md:text-xl">Withdrawing</div>
        <div className="secTitle max-md:mb-4 max-md:text-xs">Withdraw aETH/saETH, Claim ETH.</div>
      </div>
      <Tabs
        activeTab={activeTab}
        type="capsule"
        tabPosition="top"
        onChange={setActiveTab}
        renderTabHeader={(
          tabProps: TabsProps,
          DefaultTabHeader: typeof TabHeader
        ) => (
          <ButtonGroup
            className="justify-center TabBg p-1 w-fit mx-auto"
            style={{ display: "flex" }}
          >
            <Button
              shape="round"
              className={activeTab === "1" ? "btnBg" : "sectionBg fontColor"}
              style={{ padding: "0 30px" }}
              key="1"
              onClick={() => setActiveTab("1")}
            >
              Request
            </Button>
            <Button
              className={activeTab === "2" ? "btnBg" : "sectionBg fontColor"}
              shape="round"
              style={{ padding: "0 30px" }}
              key="2"
              onClick={() => setActiveTab("2")}
            >
              Claim
            </Button>
          </ButtonGroup>
        )}
      >
        <TabPane key="1" title={<Button>Primary</Button>}>
          {/* <Typography.Paragraph style={{padding:'0 15px'}}> */}
          <Typography.Paragraph>

          {(chainId && supportchain) &&
          <Typography className={classnames("white",{ModuleDark:context.Theme === 'dark',ModuleLight:context.Theme === ''})}>
          <div className="ModuleBgInner p-6 m-auto">
            <div className="white font22 font-semibold max-md:text-base">My Request</div>
            <div className="flex">
              <div className="flex-col flex-1 justify-around align-middle my-4 max-md:my-3">
                <div className="opacity-60 mb2-5 leading-normal text-sm">Ready to claim</div>
                <div
                  className="items-center _flex text-2xl max-md:text-base"
                >
                  <IconReadyToCliam
                    className="success text-base"
                    style={{ marginRight: "6px" }}
                  />
                  <span>{claimJSON.data.filter((it: any) => it.availableStatus)?.length}</span>
                </div>
              </div>
              <div className="flex-col flex-1 justify-around align-middle my-4 max-md:my-3">
                <div className="opacity-60 mb2-5 leading-normal text-sm">Pending</div>
                <div>
                  <div
                    className="items-center _flex text-2xl max-md:text-base"
                  >
                    <IconPendingTime
                      className="pending text-base"
                      style={{ marginRight: "6px" }}
                    />
                    <span>{claimJSON.data.filter((it: any) => !it.availableStatus)?.length}</span>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </Typography>
          }
            <Typography className={classnames("p-6 _shadowBig flex flex-col gap-4 fontColor max-md:p-4",{sectionBgNoRadius:chainId,sectionBg:!chainId})}>
              <Paragraph className="max-md:text-base">Request</Paragraph>
              {/* <_InputWithdrawal options={options} maxValue={aETH_balance && Number((formatEther(aETH_balance))).toFixed(4)}/> */}
              <div className="inputBg">
                <Form
                  className="p-4 withdrwal max-md:p-3"
                  autoComplete="off"
                  form={form_aETH}
                  onValuesChange={(_, v) => {
                    setValues(v.customInput);
                    setValueinModal(v.customInput?.input)
                    setWithdrwalMax(false);
                    setApproving(false);
                  }}
                >
                  <p className="gray pb-2">Send</p>
                  <Form.Item
                    rules={[
                      // {
                      //   required: true,
                      // },
                      {
                        validator: (val, cb) => {
                          // console.log(val.input);
                          if (val.input && parseFixed(val.input, 18).eq(0)) {
                            cb("Amount is required");
                          }
                          if (
                            val.select === "aETH" &&
                            val.input &&
                            aETH_balance &&
                            parseFixed(val.input, 18).gt(aETH_balance)
                          ) {
                            cb("Insufficient liquidity");
                          }
                          // if (((val.select === 'aETH') && val.input && data) && parseFixed(val.input, 18).gt(data.withdrawRemaining)) {
                          //   cb(`Daily max limit is ${data && formatNumber(formatFixed(data.withdrawRemaining, 18),4)} ETH`);
                          // }
                          if (
                            val.select === "saETH" &&
                            val.input &&
                            saETH_balance &&
                            parseFixed(val.input, 18).gt(saETH_balance)
                          ) {
                            cb("saETH Insufficient liquidity");
                          }
                          // if (((val.select === 'saETH') && val.input && data) && parseFixed(val.input, 18).mul(data.saETHdailyLimit).div(BigNumber.from(10).pow(18)).gt(data.withdrawRemaining)) {
                          //   cb(`Daily max limit is ${data && formatNumber(formatFixed(data.withdrawRemaining, 18),4)} ETH`);
                          // }
                          cb();
                        },
                      },
                    ]}
                    extra={
                      chainId &&
                      (
                      values.select === "aETH" ? (
                        <div className="flex justify-between items-center w-full">
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            aETH_balance &&
                              setValues({
                                ...values,
                                input: parseFloat(formateNormalNumber(formatEther(aETH_balance),8)).toString(),
                              });
                            aETH_balance && setValueinModal(formatEther(aETH_balance));
                            setWithdrwalMax(true);
                          }}
                        >
                          Max:{" "}
                          {aETH_balance
                            ? formatNumber(formatEther(aETH_balance),4)
                            : "-"}
                        </span>
                        {values.input && context.Data?.aETHPrice && <span>≈&nbsp;{formatNumber(formatFixed(parseFixed(values.input, 18).mul(parseFixed(context.Data?.aETHPrice,18)).div(BigNumber.from(10).pow(18)), 18), 2)}</span>}
                        </div>
                        ) : (
                        <div className="flex justify-between items-center w-full">
                          <span
                            className="cursor-pointer"
                            onClick={() => {
                              saETH_balance &&
                                setValues({
                                  ...values,
                                  input: parseFloat(formateNormalNumber(formatEther(saETH_balance),8)).toString(),
                                });
                                saETH_balance && setValueinModal(formatEther(saETH_balance))
                              setWithdrwalMax(true);
                            }}
                          >
                            Max:{" "}
                            {saETH_balance
                              ? formatNumber(formatEther(saETH_balance),4)
                              : "-"}
                          </span>
                          {values.input && context.Data?.saETHPrice && <span>≈&nbsp;{formatNumber(formatFixed(parseFixed(Number(values.input).toFixed(8).toString(), 18).mul(parseFixed(context.Data?.saETHPrice,18)).div(BigNumber.from(10).pow(18)),18), 2)}</span>}
                        </div>
                      )
                    )
                    }
                    className="mb0"
                    // labelCol={{ span: 2 }}
                    wrapperCol={{ span: 24 }}
                    initialValue={values}
                    // label={
                    //   <Image
                    //     width={30}
                    //     src={img[values.select]}
                    //     alt={values.select}
                    //     preview={false}
                    //   />
                    // }
                    field="customInput"
                  >
                    <CustomInput options={[
                      {
                      label:<><Image
                        className="w-30 mr-2 max-md:mr-1 max-md:w-6"
                        src={img['aETH']}
                        alt='aETH'
                        preview={false}
                      /><span>aETH</span></>,
                      value:'aETH'
                      },
                      {
                      label:<><Image
                        className="w-30 mr-2 max-md:mr-1 max-md:w-6"
                        src={img['saETH']}
                        alt='saETH'
                        preview={false}
                      /><span>saETH</span></>,
                      value:'saETH'
                      }]} val={values} />
                  </Form.Item>
                </Form>
                {/* <Typography.Paragraph>
                  <p>Form Data:</p>
                  <pre>{JSON.stringify(values, null, 2)}</pre>
                  {data && values.input && parseFixed(values.input, 18).mul(data.saETHdailyLimit).div(BigNumber.from(10).pow(18)).toString()}
                </Typography.Paragraph> */}
              </div>
              {/* <span style={{display:"inline-block",margin:'10px 0'}}> withdrawableAmount : {data ? formatNumberNotCarry(Number(formatFixed(values.select === 'aETH' ? data.withdrawableAmount : data.saETHWithdrawableAmount , 18))) : '-'}</span> */}
              {/* <Row className="flex align-middle leading-8 mt-2">
                <Col className="gray" span={12}>
                  Exchange rate
                </Col>
                <Col span={12} className="text-end">
                  1ETH = 1 aETH
                </Col>
              </Row>
              <Row className="flex align-middle leading-8">
                <Col className="gray" span={12}>
                  Max transaction cost
                </Col>
                <Col span={12} className="text-end">
                  $8.09
                </Col>
              </Row>
              <Row className="flex align-middle leading-8 mb-4">
                <Col className="gray" span={12}>
                  You will receive
                </Col>
                <Col span={12} className="text-end">
                  0 aETH
                </Col>
              </Row> */}
              
            {/* </Typography> */}
            {/* <Typography className="p-6 pt-1 sectionBg mt-6 _shadowSmall fontColor"> */}
              <Row
                className="flex align-middle leading-8 gap-2"
                style={{ flexWrap: "nowrap" }}
              >
                <Col span={12} onClick={(e) => Select("1")} className="inputBg">
                  <_Grid
                    key="1"
                    // hoverable={true}
                    className={
                      type === "1"
                        ? "rounded-xl cursor-pointer borderBg"
                        : "rounded-xl cursor-pointer _arco-card-grid"
                    }
                    style={{ width: "auto", minHeight: "120px" }}
                  >
                    <Card
                      className="card-demo-in-grid"
                      // hoverable
                      title={
                        <AvatarGroup >
                          <Avatar className="withDrawType">
                            <img alt="Aspida" src={Aspida} />
                          </Avatar>
                        </AvatarGroup>
                      }
                      extra="Aspida"
                      bordered={false}
                    >
                      <Row className="flex align-middle leading-8 -mt-3">
                        <Col className="gray" span={12}>
                          Rate
                        </Col>
                        <Col span={12} className="text-end">
                          1 : {willReceiveRate ? parseFloat(formateNormalNumber(formatFixed(willReceiveRate, 18),4)) : '-'}
                        </Col>
                      </Row>
                      <Row className="flex align-middle leading-8">
                        <Col className="gray" span={12}>
                          Waiting time
                        </Col>
                        <Col span={12} className="text-end">
                          0～{waitingTime}days
                        </Col>
                      </Row>
                      {/* <Row className="flex align-middle leading-8">
                        <Col className="gray" span={12}>
                          Daily Limit
                        </Col>
                        <Col span={12} className="text-end">
                          {data ? parseFloat(formateNormalNumber(formatFixed(data.withdrawRemaining, 18),4)) : '-'} ETH
                        </Col>
                      </Row> */}
                    </Card>
                  </_Grid>
                </Col>
                <Col span={12} onClick={(e) => Select("2")} className="inputBg">
                  <_Grid
                    key="2"
                    // hoverable={true}
                    className={
                      type === "2"
                        ? "rounded-xl cursor-pointer borderBg"
                        : "rounded-xl cursor-pointer _arco-card-grid"
                    }
                    style={{ width: "auto", minHeight: "120px" }}
                  >
                    <Card
                      className="card-demo-in-grid"
                      // hoverable
                      title={
                        <AvatarGroup>
                          <Avatar className="withDrawType">
                            <img alt="Curve" src={Curve} />
                          </Avatar>
                          <Avatar className="withDrawType">
                            <img alt="uniswap" src={uniswap} />
                          </Avatar>
                        </AvatarGroup>
                      }
                      extra="Use aggregators"
                      bordered={false}
                    >
                      <Row className="flex align-middle leading-8 -mt-3">
                        <Col className="gray" span={12}>
                        Best rate
                        </Col>
                        <Col span={12} className="text-end">
                          -
                        </Col>
                      </Row>
                      <Row className="flex align-middle leading-8">
                        <Col className="gray" span={12}>
                        Waiting time
                        </Col>
                        <Col span={12} className="text-end">
                        ～1-5minutes
                        </Col>
                      </Row>
                    </Card>
                  </_Grid>
                </Col>
              </Row>
              {type === "1" ? (
                <div className="gap-0  text-sm max-md:text-xs">
                  <Row className="flex align-middle leading-8">
                    <Col className="grayNoFont" span={12}>
                      Max unlock cost
                    </Col>
                    <Col span={12} className="text-end">
                      FREE
                    </Col>
                  </Row>
                  <Row className="flex align-middle leading-8">
                    <Col className="grayNoFont" span={12}>
                      Max transaction cost
                    </Col>
                    <Col span={12} className="text-end">
                    { (chainId && supportchain && maxConst) ? '$' + formatNumber(formatFixed(maxConst, 26), 4) : "-"}
                    </Col>
                  </Row>
                  <Row className="flex align-middle leading-8">
                    <Col className="grayNoFont" span={12}>
                      Exchange rate
                    </Col>
                    <Col span={12} className="text-end">
                      1 {values.select} = {willReceiveRate ? parseFloat(formateNormalNumber(formatFixed(willReceiveRate, 18),4)) : '-'} ETH
                    </Col>
                  </Row>
                </div>
              ) : (
                <Row className="flex align-middle items-center leading-8 mt-3 inputBg p-5">
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <img src={Curve} alt="Curve" className="w-10 h-10" />
                    <Col
                      className="justify-between"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "8px",
                      }}
                    >
                      <span>Curve</span>
                      <a
                        href="https://curve.fi/#/ethereum/swap"
                        target="_blank"
                        rel="noreferrer"
                        className="flex items-center"
                        style={{ color: "#3B82F6" }}
                      >
                        <span className="mr-1">Go To Curve</span>
                        <IconLaunch />
                      </a>
                    </Col>
                  </Col>
                  <Col span={12} className="text-end">
                    ≈ - ETH
                  </Col>
                </Row>
              )}
              {(type === "1") ?
              (chainId && supportchain) ? 
              (
                values.select === "aETH" ? (
                  !aETH_allowance ||
                    aETH_allowance?.eq("0") ||
                    (values.input &&
                      aETH_allowance.lt(parseFixed(values.input, 18))) ? (
                    <div
                      className={classnames(
                        "w-full py-4 text-base text-center rounded-xl cursor-pointer btnBg",
                        { disabled: approving }
                      )}
                      onClick={OnaETHApprove}
                    >
                      Approve
                    </div>
                  ) : (
                    <div
                      className={classnames("w-full py-4 text-base text-center rounded-xl cursor-pointer btnBg",{disabledNoGray:disabledBtn || !values.input,disabled: withdrawling})}
                      onClick={OnaETH_withdrawal}
                    >
                      Request Withdrawal
                    </div>
                  )
                ) : !saETH_allowance ||
                  saETH_allowance?.eq("0") ||
                  (values.input &&
                    saETH_allowance.lt(parseFixed(values.input, 18))) ? (
                  <div
                    className={classnames(
                      "w-full py-4 text-base text-center rounded-xl cursor-pointer btnBg",
                      { disabled: approving }
                    )}
                    onClick={OnsaETHApprove}
                  >
                    Approve
                  </div>
                ) : (
                  <div
                    className={classnames("w-full py-4 text-base text-center rounded-xl cursor-pointer btnBg",{disabledNoGray:disabledBtn || !values.input,disabled:withdrawling})}
                    onClick={OnsaETH_withdrawal}
                  >
                    Unstake & Withdrawal
                  </div>
                )
              ) : (
                <div
                  className="w-full py-4 text-base text-center rounded-xl cursor-pointer btnBg"
                  onClick={() => setVisible(true)}
                >
                  Connect wallet
                </div>
              )
              :<></>
            }
            </Typography>
          </Typography.Paragraph>
        </TabPane>
        <TabPane key="2" title={<Button>Primary</Button>}>
          <Typography.Paragraph>
          {(chainId && supportchain) &&
          <Typography className={classnames("white",{ModuleDark:context.Theme === 'dark',ModuleLight:context.Theme === ''})}>
          <div className="ModuleBgInner p-6 m-auto">
            <div className="white font22 font-semibold max-md:text-base">Claim</div>
            <div className="flex">
              <div className="flex-col flex-1 justify-around align-middle my-4 max-md:my-3">
                <div className="opacity-60 mb2-5 leading-normal text-sm">Available to claim</div>
                <div className="cursor-pointer text-22 font-semibold max-md:text-base">
                  {
                    claimJSON.AvailableClaim &&
                    <Tooltip
                      position="bottom"
                      trigger="hover"
                      content={formateNormalNumber(parseFloat(formatEther(claimJSON.AvailableClaim)),16)}
                    >
                    {formatNumber(parseFloat(formatEther(claimJSON.AvailableClaim)),4) + " ETH"}
                  </Tooltip>
                  }
                </div>
              </div>
              <div className="flex-col flex-1 justify-around align-middle my-4 max-md:my-3">
                <div className="opacity-60 mb2-5 leading-normal text-sm">My pending amount</div>
                <div className="flex items-center gap-2">
                  <div className="cursor-pointer text-22 font-semibold max-md:text-base">
                  {
                    claimJSON.PendingClaim &&
                    <Tooltip
                      position="bottom"
                      trigger="hover"
                      content={formateNormalNumber(parseFloat(formatEther(claimJSON.PendingClaim)),16)}
                    >
                    {formatNumber(parseFloat(formatEther(claimJSON.PendingClaim)),4) + " aETH"}
                  </Tooltip>
                  }
                </div>
                  <div className="AddTokens">
                    <Tooltip
                      position="bottom"
                      trigger="hover"
                      content="Add tokens to wallet"
                    >
                      <IconAddToken
                        className="cursor-pointer arco-icon-h5"
                        onClick={async (e) =>
                          await AddTokenToMetamask(
                            e,
                            (aETHConfig.networks as any)?.[
                              (chainId as number).toString()
                            ]?.address,
                            "aETH",
                            "18",
                            aETH,
                            connector.provider as Provider
                          )
                        }
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            {/* <Divider /> */}
            <div className="divider mb-4 max-md:mb-3"></div>
            <div className="white text-sm font-semibold max-md:text-sm">My Request</div>
            <div className="flex">
              <div className="flex-col flex-1 justify-around align-middle my-4 max-md:my-3">
                <div className="opacity-60 mb2-5 leading-normal text-sm">Ready to claim</div>
                <div
                  className="items-center _flex text-2xl max-md:text-base"
                  // style={{ display: "flex", fontSize: "24px" }}
                >
                  <IconReadyToCliam
                    className="success text-base"
                    style={{ marginRight: "6px" }}
                  />
                  <span>{claimJSON.data.filter((it: any) => it.availableStatus)?.length}</span>
                </div>
              </div>
              <div className="flex-col flex-1 justify-around align-middle my-4 max-md:my-3">
                <div className="opacity-60 mb2-5 leading-normal text-sm">Pending</div>
                <div>
                  <div
                    className="items-center _flex text-2xl max-md:text-base"
                    // style={{ display: "flex", fontSize: "24px" }}
                  >
                    <IconPendingTime
                      className="pending text-base"
                      style={{ marginRight: "6px" }}
                    />
                    <span>{claimJSON.data.filter((it: any) => !it.availableStatus)?.length}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Typography>
          }
            <Typography className={classnames("p-6 _shadowBig flex-col gap-4 fontColor max-md:p-4",{sectionBgNoRadius:chainId,sectionBg:!chainId})}>
              <Paragraph className="fontColor max-md:text-base">Claim</Paragraph>
              {(chainId && supportchain) ?
              claimJSON.data.length > 0 ?
                <Row className="flex align-middle leading-8 my-2 inputBg px-4">
                  {
                    claimJSON.data.map((item: any) => (
                      <Col className="divider-demo-flex-content py-4" key={item.key}>
                        <span className="avatar">
                          <IconReadyToCliam className={classnames({ success: item.availableStatus })} />
                        </span>
                        <div className="content flex justify-between">
                          <div className="flex flex-col">
                            <Text className="text-xl font-semibold max-md:text-sm">{formatNumber(formatFixed(item.amount, 18), 4)} aETH</Text>
                            <span className="grayColor font-normal">≈&nbsp;{context.ETHFeed && `$${formatNumber(formatFixed(BigNumber.from(item.amount).mul(BigNumber.from(context.ETHFeed.price).toString()), 26), 4)}`}</span>
                          </div>
                          <div className="flex justify-start items-start">
                            {
                              item.availableStatus ?
                                <Tag className="readyClaim" bordered={false}>
                                  Ready to claim
                                </Tag>
                                : <Tag className="pendingClaim" bordered={false}>
                                  Pending
                                </Tag>
                            }
                            {/* <a
                              href="https://goerli.etherscan.io/tx/0xbb15b121f790fbf920862d53dae475537677cddf65b10e19b1821462785d1fa2"
                              target="_blank"
                              rel="noreferrer"
                              className="inline-flex"
                            >
                              <IconLaunch className="LinkClaim cursor-pointer" />
                            </a> */}
                          </div>
                        </div>
                      </Col>
                    ))
                  }
                </Row>
                :
                <div className="flex justify-center items-center mt-2 mb-6 inputBg h-24">
                    <div className="flex items-center gray">
                      No claims detected
                    </div>
                </div>
                :<Row className="gray flex align-middle text-center leading-8 my-2 py-20 inputBg px-4">
                  Connect wallet to see your withdrawal requests
                </Row>
              }
              {/* <Row className="flex align-middle leading-10 my-3">
                <Col className="gray" span={12}>
                  Max transaction cost
                </Col>
                <Col span={12} className="text-end">
                  $8.09
                </Col>
              </Row> */}
              {(chainId && supportchain) ? (
                <>
                  <div
                    className={classnames("w-full py-4 text-base text-center rounded-xl cursor-pointer btnBg", {nodataClaim:BigNumber.from(claimJSON.AvailableClaim).lte(0),disabled: claiming })}
                    onClick={OnClaim}
                  >
                    Claim
                  </div>
                </>
              ) : (
                <div
                  className="w-full py-4 text-base text-center rounded-xl cursor-pointer btnBg"
                  onClick={() => setVisible(true)}
                >
                  Connect wallet
                </div>
              )}
            </Typography>
          </Typography.Paragraph>
        </TabPane>
      </Tabs>
      <Modal
        // title={
        //   <Typography className="text-left">
        //   </Typography>
        // }
        visible={withdrwalvisible}
        onOk={() => setWithdrwalvisible(false)}
        onCancel={() => setWithdrwalvisible(false)}
        autoFocus={false}
        maskClosable={false}
        footer={null}
        // closeIcon={<img src={Close}/>}
      >
        {
          <div className="flex flex-col justify-center items-center text-center mt-6 gap-4 max-md:gap-3">
            <div className={classnames("IconSyncStake flex  flex-row justify-center items-center cursor-pointer", { pendingBg: ((withdrwalTransactionStatus === 0) && (success !== 0)), successBg: withdrwalTransactionStatus === 1, failBg: withdrwalTransactionStatus === 2, })} style={{ color: '#fff' }}>
              {
                withdrwalTransactionStatus === 0 ?
                ((success === 0) ?
                <IconDone width="60" height="60"/>
                // <IconClockCircle
                //       className="pending"
                //       style={{fontSize:'60px'}}
                // />
                // <img src={successIcon}/>
                :
                // <img src={sync} className="rotate"/>
                <div className="rotate">
                  <IconResearch width="60" height="60"/>
                </div>
                )
                  : withdrwalTransactionStatus === 1 ?
                    // <img src={successIcon}/>
                    <IconDone width="60" height="60"/>
                    : <IconFail width="60" height="60"/>
                    // <img src={fail}/>
              }
            </div>
            <div className="font26 max-md:text-base">
            {
              withdrwalTransactionStatus === 0 ?
                ((success === 0)?
                <span>{values.select === "aETH" ? "Requesting Withdrawal" : "Unstake & Withdrawal"} sent successfully {formatNumber(parseFloat(valueinModal),4)} {values.select}</span>
                :<span>{values.select === "aETH" ? "Requesting Withdrawal" : "Unstake & Withdrawal"} for {formatNumber(parseFloat(valueinModal),4)} {values.select}</span>)
                : withdrwalTransactionStatus === 1 ?
                  <span>{values.select === "aETH" ? "Request Withdrawan" : "Unstake & Withdrawal"} successfully {formatNumber(parseFloat(valueinModal),4)} {values.select}</span>
                  : withdrwalTransactionStatus === 2 ? <span>
                    {/* Cancelled by user */}
                    {errorTxt?.reason ?? errorTxt}
                  </span>
                  :<span>
                    {errorTxt}
                   </span>
            }
            </div>
            <div className="flex flex-col items-center grayNoFont font20 pt-5 max-md:text-xs max-md:pt-3">
            {
              withdrwalTransactionStatus === 0 ?
                  (success === 2) && 
                  <div>
                    Confirm this transaction in your wallet
                  </div>
                  // :(success === 0) ? <div className="gray font20">
                  //     Awaiting block conformation
                  // </div>
                  // :<></>)
                : withdrwalTransactionStatus == 1 ?
                  <div>
                    Earn rewards by staking aETH
                  </div>
                  : withdrwalTransactionStatus == 2 ? <div>
                    {/* User denied the transaction signature */}
                    {/* {JSON.stringify(errorTxt, null, 2)} */}
                    {errorTxt?.reason ?? errorTxt}
                  </div>
                  :<div>
                    Please re-initiate the request
                  </div>
            }
            {
              (withdrwalTransactionStatus !== 2) && !(success===2) &&
              <a href={`${(TX_LINK as any)[chainId as number]}${hash}`} target="_blank" rel="noreferrer" className="flex items-center ml-3" style={{ color: '#3B82F6'}}>
                {/* <IconLaunch /> */}
                <span className="mr-1">View on Etherscan</span>
                <IconExternalLink />
              </a>
            }
            </div>
          </div>
        }
      </Modal>

      {/* Claim Modal */}
      <Modal
        visible={claimvisible}
        onOk={() => setClaimvisible(false)}
        onCancel={() => setClaimvisible(false)}
        autoFocus={false}
        maskClosable={false}
        footer={null}
        // closeIcon={<img src={Close}/>}
      >
        {
          <div className="flex flex-col justify-center items-center mt-6 gap-4 max-md:gap-3">
            <div className={classnames("IconSyncStake flex  flex-row justify-center items-center cursor-pointer", { pendingBg: claimTransactionStatus === 0, successBg: claimTransactionStatus === 1, failBg: claimTransactionStatus === 2, })} style={{ color: '#fff' }}>
              {
                claimTransactionStatus === 0 ?
                // <img src={sync} className="rotate"/>
                <div className="rotate">
                  <IconResearch width="60" height="60"/>
                </div>
                  : claimTransactionStatus === 1 ?
                  // <img src={successIcon}/>
                  <IconDone width="60" height="60"/>
                    : <IconFail width="60" height="60"/>
                    // <img src={fail}/>
              }
            </div>
            <div className="font26 max-md:text-base">
            {
              claimTransactionStatus === 0 ?
                <span>Claiming ETH</span>
                // <span>Claiming {formatNumber(parseFloat(formatEther(hasClaim)),4)} ETH</span>
                : claimTransactionStatus === 1 ?
                  <span>ETH has been claimed</span>
                  // <span>{formatNumber(parseFloat(formatEther(hasClaim)),4)} ETH has been claimed</span>
                  : claimTransactionStatus === 2 ? 
                  <span>{errorTxt?.reason ?? errorTxt}</span>
                  :<span>{errorTxt}</span>
            }
            </div>
            <div className="flex flex-col items-center grayNoFont font20 pt-5 max-md:text-xs max-md:pt-3">
            {
              claimTransactionStatus === 0 ?
                <div>
                  Confirm this transaction in your wallet
                </div>
                : claimTransactionStatus == 1 ?
                  <div>
                    Claiming operation was successful
                  </div>
                  : claimTransactionStatus == 2 ? <div>
                    {errorTxt?.reason ?? errorTxt}
                  </div>
                  :<div>
                      Please re-initiate the request
                    </div>
            }
            {
              ((claimTransactionStatus === 0) || (claimTransactionStatus === 3)) &&
              <a href={`${(TX_LINK as any)[chainId as number]}${hash}`} target="_blank" rel="noreferrer" className="flex items-center ml-3" style={{ color: '#3B82F6'}}>
                {/* <IconLaunch /> */}
                <span className="mr-1">View on Etherscan</span>
                <IconExternalLink />
              </a>
            }
            </div>
          </div>
        }
      </Modal>
    </div>
  );
};

export default Withdrwal;
