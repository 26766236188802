import type { BigNumber } from '@ethersproject/bignumber'
import { formatEther } from '@ethersproject/units'
import type { Web3ReactHooks } from '@web3-react/core'
import { useEffect, useState } from 'react'
import accountLog from "../assets/img/accountLog.svg"

function useBalance(
  provider?: ReturnType<Web3ReactHooks['useProvider']>,
  account?:string
): BigNumber[] | undefined {
  const [balance, setBalance] = useState<BigNumber[] | undefined>()

  useEffect(() => {
    if (provider && account) {
      let stale = false

      void Promise.all([account].map((account) => provider.getBalance(account))).then((balance) => {
        console.log(balance)
        if (stale) return
        setBalance(balance)
      })
      
      return () => {
        stale = true
        setBalance(undefined)
      }
    }
  }, [provider, account])

  return balance
}

export function Account({
  account,
  provider,
  ENSName,
}: {
  account: ReturnType<Web3ReactHooks['useAccount']>
  provider: ReturnType<Web3ReactHooks['useProvider']>
  ENSName: ReturnType<Web3ReactHooks['useENSName']>
}) {
  const balance = useBalance(provider, account)

  if (account === undefined) return null

  return (
    <div key={account} className='flex justify-between items-center gap-2 mr-8 font28 max-md:text-base'>
      <img src={accountLog} width="40"/>
      <span>{ENSName ?? (account.substring(0,5) + '...' + account.substring(account?.length-4,account?.length))}</span>
    </div>
  )
}
