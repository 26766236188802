import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
  ReactNode,
} from "react";
import isMobile from "is-mobile";
import { Context } from "../App";
import { ethers } from "ethers";
import {
  useWeb3React,
} from "@web3-react/core";
import type { Provider } from "@web3-react/types";
import { parseFixed, formatFixed } from '@ethersproject/bignumber'
import { formatEther } from "@ethersproject/units";
import {
  Typography,
  Grid,
  Tooltip,
  Image,
  Form,
  Input,
  Select,
  Message,
  Modal,
} from "@arco-design/web-react";
const { Title, Paragraph, Text } = Typography;
const Row = Grid.Row;
const Col = Grid.Col;
import {
  IconArrowRight,
  IconCheck,
  IconClose,
  IconLaunch,
  IconMoreVertical,
  IconPlusCircleFill,
  IconSync,
} from "@arco-design/web-react/icon";
import { IconDone,IconFail, IconReadyToCliam, IconResearch,IconAddToken, IconExternalLink, IconDarkAArriveB} from "@arco-iconbox/react-lsd";
import classnames from "classnames";
import { AddTokenToMetamask, _initContract, formatNumber, formatNumberNotCarry, formateNormalNumber, initContract, multicall, multicall_target } from "../utils";
import { TX_LINK } from "../constants";
import useBalance from "../hooks/useBalance";
import useBalanceETH from "../hooks/useBalanceETH";
import useAllowance from "../hooks/useAllowance";
import _Input from "./_Input";
import _Input2 from "./_Input2";
import aETHConfig from "../contracts/aETH.json";
import saETHConfig from "../contracts/saETH.json";
import stETHConfig from "../contracts/stETH.json";
import StETHMinterConfig from "../contracts/StETHMinter.json";
import CorePrimaryConfig from "../contracts/CorePrimary.json";
import { BigNumber } from "@ethersproject/bignumber";
import aETH from "../assets/img/token/aETH.svg";
import stETH from "../assets/img/token/stETH.svg";
import ETH from "../assets/img/token/ETH.svg";
import saETH from '../assets/img/token/saETH.svg'
import ModuleLight from "../assets/img/ModuleLight.svg";
import ModuleDark from "../assets/img/ModuleDark.svg";
import sync from "../assets/img/sync.svg";
import success from "../assets/img/success.svg";
import fail from "../assets/img/fail.svg";
import Close from "../assets/img/Close.svg"
import { CHAINS } from "../chains";
import { GAS }  from "../constants";

interface CustomInputProps {
  options: { label: ReactNode | Element | string; value: string | number; disabled?: boolean; extra?: any }[],
  val: Record<string, string>
}
const img: Record<string, string> = {
  stETH: stETH,
  ETH: ETH,
  aETH: aETH,
  saETH: saETH
};
const CustomInput: React.FC<CustomInputProps> = (props: any) => {
  const value = props.value || {};
  const val = props.val || {};
  // console.log(props.value,props.val)
  // console.log(value);
  const handleChange = (newValue: any) => {
    props.onChange && props.onChange(newValue);
  };

  return (
    <div className="flex w-full">
      {/* <Image
      width={40}
      src={img[value.select]}
      alt={value.select}
      preview={false}
    /> */}
      {/* value.replace(/[^\d.]/g,""); //清除"数字"和"."以外的字符
        value.replace(/^\./g,""); //验证第一个字符是数字
        value.replace(/\.{2,}/g,""); //只保留第一个, 清除多余的
        value.replace(".","$#$").replace(/\./g,"").replace("$#$","."); //只能输入一个 "."
        value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3'); //只能输入两个小数 */}
      <Input
        value={val.input}
        onChange={(v) => {
          handleChange({
            ...value,
            input: v
              .trim()
              .replace(".", "$#$")
              .replace(/\./g, "")
              .replace("$#$", ".")
              .replace(/[^\d.]/g, "")
              .replace(/^\./g, "")
              .replace(/^(\-)*(\d+)\.(\d\d\d\d\d\d\d\d).*$/, "$1$2.$3"),
          });
        }}
        placeholder="0.0"
        // allowClear
        // onKeyUp={value=value.replace(/[^\d]/g,'').replace(/^0{1,}/g,'')}
        addBefore={
          <Select // select component has defined error style
            error={props.error}
            placeholder="Please select ..."
            style={{ minWidth: 90 }}
            className="inputBg"
            value={val.select}
            options={props.options}
            onChange={(v) => {
              // handleChange({ ...value, select: v });
              // 切换 token  清空 输入框
              handleChange({ input: undefined, select: v });
            }}
          />
        }
        beforeStyle={{ border: 0 }}
      />
    </div>
  );
};

interface CustomInputProps2 {
  options: { label: ReactNode | Element | string; value: string | number; disabled?: boolean; extra?: any }[],
  val: Record<string, string>
}
const CustomInput2: React.FC<CustomInputProps2> = (props: any) => {
  const value = props.value || {};
  const val = props.val || {};
  // console.log(props,value,val)
  const handleChange = (newValue: any) => {
    props.onChange && props.onChange(newValue);
  };

  return (
    <div className='flex'>
      {/* <Image
      width={40}
      src={img[value.select]}
      alt={value.select}
      preview={false}
    /> */}
      <Input
        disabled
        value={val.input}
        onChange={(v) => {
          handleChange({
            ...value, input: v
              .trim()
              .replace(".", "$#$")
              .replace(/\./g, "")
              .replace("$#$", ".")
              .replace(/[^\d.]/g, "")
              .replace(/^\./g, "")
              .replace(/^(\-)*(\d+)\.(\d\d\d\d\d\d\d\d).*$/, "$1$2.$3"),
          });
        }}
        className="inputBg"
        addBefore={
          <Select // select component has defined error style
            error={props.error}
            placeholder='Please select ...'
            style={{ minWidth: 90 }}
            className="inputBg _flex items-center"
            value={val.select}
            options={props.options}
            onChange={(v) => {
              // console.log(value,v)
              handleChange({ ...value, select: v });
            }}
          />
        }
        beforeStyle={{ border: 0 }}
      />
    </div>
  );
}

interface MintProps {
  setVisible: (visible: boolean) => void;
}
const Mint: React.FC<MintProps> = ({ setVisible }) => {
  const context = useContext(Context);
  const { provider, connector, account, chainId } = useWeb3React();
  const supportchain = chainId ? Object.keys(CorePrimaryConfig.networks).includes((chainId as number).toString()) : false;

  const [mintvisible, setMintvisible] = useState(false);
  // 0:初始状态 1: success 2: fail
  const [mintTransactionStatus, setMintTransactionStatus] = useState<number>(2)
  const [errorTxt, setErrorTxt] = useState<any>("")
  const [hash, setHash] = useState<string | undefined>(undefined)

  const [refreshAllowance, setrefreshAllowance] = useState<number>(0)
  const stETH_allowance = useAllowance(stETHConfig, StETHMinterConfig, refreshAllowance);
  const [approving, setApproving] = useState<boolean>(false)

  const [refreshBalance, setrefreshBalance] = useState<number>(0)
  const balance = useBalanceETH(provider, account, supportchain);
  const aETH_balance = useBalance(provider, aETHConfig, account, chainId, supportchain, refreshBalance);
  const saETH_balance = useBalance(provider, saETHConfig, account, chainId, supportchain, refreshBalance);
  const stETH_balance = useBalance(provider, stETHConfig, account, chainId, supportchain, refreshBalance);

  const [mintMax, setMintMax] = useState<boolean>(false)
  const [minting, setMinting] = useState<boolean>(false)
  const config: Record<string, string[]> = {
    ETH: ["aETH", "saETH"],
    stETH: ["aETH"],
  };
  const [options2, setOptions2] = useState(["aETH", "saETH"]);
  const [_Options2,set_Options2] = useState([
    {
    label:<><Image
      className="w-30 mr-2 max-md:mr-1 max-md:w-6"
      src={img['aETH']}
      alt='aETH'
      preview={false}
    /><span>aETH</span></>,
    value:'aETH'
    },
    {
    label:<><Image
      className="w-30 mr-2 max-md:mr-1 max-md:w-6"
      src={img['saETH']}
      alt='saETH'
      preview={false}
    /><span>saETH</span></>,
    value:'saETH'
    }])

  const [form] = Form.useForm();
  const [values, setValues] = useState<any>({ select: "ETH", input: undefined });
  const [valueinModal, setValueinModal] = useState<any>(undefined);
  const [valueinModal2, setValueinModal2] = useState<any>(undefined);
  const [form1] = Form.useForm();
  const [values2, setValues2] = useState<any>({ select: "aETH", input: undefined });
  const [willReceiveRate, setWillReceiveRate] = useState<BigNumber | undefined>(undefined)
  const [disabledBtn, setDisabledBtn] = useState<boolean>(false)


  // const _willReceive =  useMemo(() => {
  //   if(chainId && account && provider && values.input){
  //     if(values.select === 'ETH'){
  //       if(values2.select === 'aETH'){
  //         return mintMax ? balance :parseFixed(values.input,18)
  //       }else{
  //         const saETH = initContract((saETHConfig.networks as any)?.[chainId.toString()]?.address, saETHConfig.abi,provider)
  //         const receive_saETH = saETH.previewDeposit(parseFixed(values.input, 18));
  //         return receive_saETH;
  //       }
  //     }else{
  //       const StETHMinter = initContract((StETHMinterConfig.networks as any)?.[chainId.toString()]?.address, StETHMinterConfig.abi,provider)
  //         const stETH_aETH = StETHMinter.convertToAETH(parseFixed(values.input, 18));
  //         return stETH_aETH;
  //     }
  //   }

  // }, [values,values2,mintMax,balance,chainId,account,provider])
  const _willReceiveRate = useMemo(() => {
    if (chainId && account && provider && supportchain) {
      if (values.select === 'ETH') {
        if (values2.select === 'aETH') {
          return BigNumber.from(10).pow(18)
        } else {
          const saETH = initContract((saETHConfig.networks as any)?.[chainId.toString()]?.address, saETHConfig.abi, provider)
          const ETH_saETH = saETH.previewDeposit(BigNumber.from(10).pow(18));
          return ETH_saETH;
        }
      } else {
        const StETHMinter = initContract((StETHMinterConfig.networks as any)?.[chainId.toString()]?.address, StETHMinterConfig.abi, provider)
        const stETH_aETH = StETHMinter.convertToAETH(BigNumber.from(10).pow(18));
        return stETH_aETH;
      }
    }

  }, [values.select, values2.select, balance, chainId, supportchain, account, provider])
  const getReceive = useCallback(async () => {
    setWillReceiveRate(await _willReceiveRate)
    // console.log("_willReceiveRate",(await _willReceiveRate)?.toString())
  }, [_willReceiveRate])

  useEffect(() => {
    // if(chainId === undefined){
      console.log("isMobile",isMobile())
    setMintvisible(false)
    setValues({ ...values, input: undefined })
    setValueinModal(undefined)
    setValues2({ select: config[values.select][0], input: undefined })
    setValueinModal2(undefined)
    setOptions2(config[values.select]);
    const _Options2_ = config[values.select].map((item:string)=>{
      return {
        label:<><Image
          className="w-30 mr-2 max-md:mr-1 max-md:w-6"
          src={img[item]}
          alt={item}
          preview={false}
        /><span>{item}</span></>,
        value:item
        }
    })
    set_Options2(_Options2_);
    // }
    form.resetFields()
  }, [chainId, account]);
  // 切换账号清空输入框
  // useEffect(() => {
  //   if(account && chainId){
  //     setValues({...values,input:undefined})
  //     setValues2({select:config[values.select][0],input:undefined})
  //     setOptions2(config[values.select]);
  //     form.resetFields()
  //   }
  // }, [account,chainId]);

  useEffect(() => {
    if (chainId && supportchain) {
      getReceive();
      // setValues2({select:config[values.select].includes(values2.select) ? values2.select : config[values.select][0],input:values.input ? (values.input).toString() : undefined})
      setValues2({ select: config[values.select].includes(values2.select) ? values2.select : config[values.select][0], input: (values.input && willReceiveRate) ? parseFloat(Number(formatEther(parseFixed(values.input, 18).mul(willReceiveRate).div(BigNumber.from(10).pow(18)))).toFixed(8)).toString() : undefined })
      // setValueinModal2((values.input && willReceiveRate) ? parseFloat(Number(formatEther(parseFixed(values.input, 18).mul(willReceiveRate).div(BigNumber.from(10).pow(18)))).toFixed(8)).toString() : undefined)
      setOptions2(config[values.select]);
      const _Options2_ = config[values.select].map((item:string)=>{
        return {
          label:<><Image
            className="w-30 mr-2 max-md:mr-1 max-md:w-6"
            src={img[item]}
            alt={item}
            preview={false}
          /><span>{item}</span></>,
          value:item
          }
      })
      set_Options2(_Options2_);
    }
  }, [chainId, supportchain, values, values2.select, willReceiveRate]);
  useEffect(() => {
    if (chainId && supportchain) {
      getReceive();
      setValueinModal2((valueinModal && willReceiveRate) ? parseFloat(Number(formatEther(parseFixed(valueinModal, 18).mul(willReceiveRate).div(BigNumber.from(10).pow(18)))).toFixed(8)).toString() : undefined);
  }
  }, [chainId, supportchain, valueinModal, willReceiveRate]);

  const OnstETHApprove = useCallback(async () => {
    if (!account || !chainId || !(window.ethereum)) return;
    setApproving(true)
    const stETH = _initContract((stETHConfig.networks as any)?.[(chainId).toString()]?.address, stETHConfig.abi)

    const _provider = new ethers.providers.Web3Provider(window?.ethereum)
    const signer = _provider.getSigner(account)
    try {
      const receipt = await stETH.connect(signer).approve(
        (StETHMinterConfig.networks as any)?.[chainId.toString()]?.address,
        ethers.constants.MaxUint256
      )
      // console.log("receipt: ", receipt);
      setApproving(false)
      // const refreshId = setTimeout(() => {
      setrefreshAllowance(Math.random())
      //   clearTimeout(refreshId)
      // }, 500)

      // receipt.getTransaction((hash: string) => {
      //   console.log("transactionHash: ", hash);
      // })
      // receipt.getTransactionReceipt()((receipt: any) => {
      //   console.log("receipt: ", receipt);
      //   const refreshId = setTimeout(() => {
      //     setrefreshAllowance(Math.random())
      //     clearTimeout(refreshId)
      //   }, 500)
      // })
    } catch (error) {
      setApproving(false)
      console.log("error", error)
    }

  }, [account, chainId])
  const OnETH_aETH = useCallback(async () => {
    if (form && chainId && account && window?.ethereum) {
      try {
        if (!values.input) return;
        await form.validate();
        setMinting(true)
        setMintTransactionStatus(0)
        setHash(undefined)
        setErrorTxt("")
        const CorePrimary = _initContract((CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address, CorePrimaryConfig.abi)
        const _provider = new ethers.providers.Web3Provider(window?.ethereum)
        const signer = _provider.getSigner(account)
        // console.log(CorePrimary.connect(signer), signer, parseFixed(values.input, 18))
        try {
          setMintvisible(true)
          const receipt = await CorePrimary.connect(signer)
          ["submit()"]({
            value:
              mintMax ? balance : parseFixed(values.input, 18)
          }
          )
          const re = await receipt.wait()
          // console.log("re",re)
          if(re.status === 1){
            setMinting(false)
            setMintTransactionStatus(1);
            setValues({ ...values, input: undefined })
            setrefreshBalance(Math.random())
          }else{
            setMinting(false)
            setErrorTxt("Failed to send request withdrawal")
            setMintTransactionStatus(3)
          }
          setHash(receipt.hash)
        } catch (error) {
          console.log("error", error)
          setMinting(false)
          setErrorTxt("user rejected transaction")
          setMintTransactionStatus(2)
        }
        // Message.info('校验通过，提交成功！');
      } catch (_) {
        // console.log(form.getFieldsError());
        // Message.error('校验失败，请检查字段！');
      }
    }
  }, [values, mintMax, balance, chainId, account, window?.ethereum])

  const OnETH_saETH = useCallback(async () => {
    if (form && chainId && account && window?.ethereum) {
      try {
        if (!values.input) return;
        await form.validate();
        setMinting(true)
        setMintTransactionStatus(0)
        setHash(undefined)
        setErrorTxt("")
        const CorePrimary = _initContract((CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address, CorePrimaryConfig.abi)
        const _provider = new ethers.providers.Web3Provider(window?.ethereum)
        const signer = _provider.getSigner(account)
        try {
          setMintvisible(true)
          const receipt = await CorePrimary.connect(signer)
            .submitAndStake(
              account,
              {
                value: mintMax ? balance : parseFixed(values.input, 18),
              }
            )
          const re = await receipt.wait()
          // console.log("re",re)
          if(re.status === 1){
            setMinting(false)
            setMintTransactionStatus(1);
            setValues({ ...values, input: undefined })
            setrefreshBalance(Math.random())
          }else{
            setMinting(false)
            setErrorTxt("Failed to send request withdrawal")
            setMintTransactionStatus(3);
          }
          setHash(receipt.hash);
        } catch (error) {
          console.log("error", error)
          setMinting(false)
          setErrorTxt("user rejected transaction")
          setMintTransactionStatus(2)
        }
        // Message.info('校验通过，提交成功！');
      } catch (_) {
        // console.log(form.getFieldsError());
        // Message.error('校验失败，请检查字段！');
      }
    }
  }, [values, mintMax, balance, chainId, account, window?.ethereum])

  const OnstETH_aETH = useCallback(async () => {
    if (form && chainId && account && window?.ethereum) {
      try {
        if (!values.input) return;
        await form.validate();
        setMinting(true)
        setMintTransactionStatus(0)
        setHash(undefined)
        setErrorTxt("")
        const StETHMinterContract = _initContract((StETHMinterConfig.networks as any)?.[chainId.toString()]?.address, StETHMinterConfig.abi)
        const _provider = new ethers.providers.Web3Provider(window?.ethereum)
        const signer = _provider.getSigner(account)
        try {
          setMintvisible(true)
          const receipt = await StETHMinterContract.connect(signer)
          ["deposit(uint256,address)"](
            mintMax ? stETH_balance : parseFixed(values.input, 18),
            account
          )
          const re = await receipt.wait()
          if(re.status === 1){
            setMinting(false)
            setMintTransactionStatus(1);
            setValues({ ...values, input: undefined })
            setrefreshBalance(Math.random())
            setrefreshAllowance(Math.random())
          }else{
            setMinting(false)
            setErrorTxt("Failed to send request withdrawal")
            setMintTransactionStatus(3);
          }
          setHash(receipt.hash);
          
        } catch (error) {
          console.log("error", error)
          setMinting(false)
          setErrorTxt("user rejected transaction")
          // console.log(JSON.stringify(error, null, 2))
          setMintTransactionStatus(2)
        }
        // Message.info('校验通过，提交成功！');
      } catch (_) {
        setMinting(false)
        // console.log(form.getFieldsError());
        // Message.error('校验失败，请检查字段！');
      }
    }
  }, [values, mintMax, stETH_balance, chainId, account, window?.ethereum])
  // 关闭弹出框，重置为初始状态
  useEffect(() => {
    if (!mintvisible) {
      setMintTransactionStatus(0)
      setHash(undefined)
      setErrorTxt("")
    }
  }, [mintvisible, values])
  // const [totalaETHMinted, settotalaETHMinted] = useState<BigNumber | undefined>(undefined)
  // const [aETHMarketCap, setaETHMarketCap] = useState<BigNumber | undefined>(undefined)
  // const [dailyLimit_ETH, setdailyLimit_ETH] = useState<BigNumber | undefined>(undefined)
  const [totalLimit_stETH, settotalLimit_stETH] = useState<BigNumber | undefined>(undefined)
  const [depositAmount, setDepositAmount] = useState<BigNumber | undefined>(undefined)

  const FetchData = useCallback(async () => {
    if (provider && chainId && account && window.ethereum && context.ETHFeed) {
      try {
        const rpcUrl = CHAINS[chainId]?.urls[Math.floor(Math.random() * CHAINS[chainId]?.urls.length)]
        // // const _provider = new ethers.providers.Web3Provider(window?.ethereum)
        const multical_params = [
          // {
          //   target: (CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address,
          //   call: ["submitRemaining()(uint256)"],
          //   key: "_dailyLimit_ETH"
          // },
          // {
          //   target: (aETHConfig.networks as any)?.[chainId.toString()]?.address,
          //   call: ["totalSupply()(uint256)"],
          //   key: "_totalaETHMinted"
          // },
          {
            target: (StETHMinterConfig.networks as any)?.[chainId.toString()]?.address,
            call: ["depositCap()(uint256)"],
            key: "_totalLimit_stETH"
          },
          {
            target: (StETHMinterConfig.networks as any)?.[chainId.toString()]?.address,
            call: ["depositAmount()(uint256)"],
            key: "depositAmount"
          }
        ]
        const res = await multicall(rpcUrl, multicall_target[chainId], multical_params)
        if (!res) return;
        // const CorePrimary = initContract((CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address, CorePrimaryConfig.abi, provider);
        // const aETH = initContract((aETHConfig.networks as any)?.[chainId.toString()]?.address, aETHConfig.abi, provider);
        // const StETHMinterContract = initContract((StETHMinterConfig.networks as any)?.[chainId.toString()]?.address, StETHMinterConfig.abi,provider)
        // const _totalaETHMinted = await aETH.totalSupply();
        // const _aETHMarketCap = (await aETH.totalSupply()).mul(context.ETHFeed.price)
        // const _dailyLimit_ETH = await CorePrimary.submitRemaining()
        // const _totalLimit_stETH = await StETHMinterContract.depositCap()

        // const _aETHMarketCap = res.results.transformed._totalaETHMinted.mul(context.ETHFeed.price)
        // settotalaETHMinted(res.results.transformed._totalaETHMinted)
        // setaETHMarketCap(_aETHMarketCap)
        // setdailyLimit_ETH(res.results.transformed._dailyLimit_ETH)
        settotalLimit_stETH(res.results.transformed._totalLimit_stETH)
        setDepositAmount(res.results.transformed.depositAmount)
        setrefreshBalance(Math.random())

      } catch (error) {
        console.log(error)
      }
    }
  }, [provider, chainId, account, context.ETHFeed])
  const [maxConst,setMaxConst] = useState<string | undefined>(undefined)
  const FetchGas = useCallback(async (value:string = "10000000000000000") => {
    if (provider && chainId && account && window.ethereum && supportchain && context.ETHFeed) {
      try {
          const { gasPrice } = await provider.getFeeData()
          // const data = await provider.getFeeData()
          // console.log(data)
          // const _provider = new ethers.providers.Web3Provider(window?.ethereum)
          // const signer = _provider.getSigner(account)
        if (values.select === "ETH") {
          // const CorePrimary = _initContract((CorePrimaryConfig.networks as any)?.[chainId.toString()]?.address, CorePrimaryConfig.abi)
          if (values2.select === "aETH") {
            // const ETH_aETH_gas = await CorePrimary.connect(signer).estimateGas["submit()"]({ value })
            // console.log(ETH_aETH_gas.toString())
            const ETH_aETH_Cost = BigNumber.from(GAS.submit).mul(BigNumber.from(gasPrice)).mul(BigNumber.from(context.ETHFeed.price)).toString()
            setMaxConst(ETH_aETH_Cost)
            // console.log(ETH_aETH_gas.toString(),gasPrice?.toString(),context.ETHFeed.price.toString())
          } else {
            // const ETH_saETH_gas = await CorePrimary.connect(signer).estimateGas.submitAndStake(account, { value })
            const ETH_saETH_Cost = BigNumber.from(GAS.submitAndStake).mul(BigNumber.from(gasPrice)).mul(BigNumber.from(context.ETHFeed.price)).toString()
            setMaxConst(ETH_saETH_Cost)
            // console.log(ETH_saETH_gas.toString(),gasPrice?.toString(),context.ETHFeed.price.toString())
          }
        } else {
          // const StETHMinterContract = _initContract((StETHMinterConfig.networks as any)?.[chainId.toString()]?.address, StETHMinterConfig.abi)
          // const stETH_aETH_gas = await StETHMinterContract.connect(signer).estimateGas["deposit(uint256,address)"](value, account)
          const stETH_aETH_Cost = BigNumber.from(GAS.deposit).mul(BigNumber.from(gasPrice)).mul(BigNumber.from(context.ETHFeed.price)).toString()
          setMaxConst(stETH_aETH_Cost)
          // console.log(stETH_aETH_gas.toString(),gasPrice?.toString(),context.ETHFeed.price.toString())
        }
      } catch (error) {
        console.log(error)
        setMaxConst(undefined)
      }
    }
  }, [provider, chainId, account, supportchain, context.ETHFeed, values.select, values2.select])
  useEffect(()=>{
    FetchGas()
  },[provider, chainId, account, supportchain, context.ETHFeed,values.select,values2.select])

  useEffect(() => {
    window.mint && clearInterval(window.mint)
    // FetchData()
    const target = () => {
      if (provider && chainId && account && supportchain && context.ETHFeed) {
        FetchData()
      }
      return target
    }
    window.mint = setInterval(target(), 1000 * 15);
    return () => {
      clearInterval(window.mint);
    };
  }, [provider, chainId, account, supportchain, context.ETHFeed])
  // useEffect(() => {
  //   if(values.select === 'ETH'){
  //     if (mintMax  && balance && dailyLimit_ETH) {
  //       if (balance?.gt(dailyLimit_ETH)) {
  //         form.setFields({
  //           'customInput': {
  //             error: {
  //               message: `Daily max limit is ${dailyLimit_ETH && formatNumber(formatFixed(dailyLimit_ETH, 18), 4)} ETH`,
  //             },
  //           },
  //         });
  //         setDisabledBtn(true)
  //         return;
  //       }
  //     }
  //     setDisabledBtn(false)
  //   }
  // }, [mintMax, values, balance, dailyLimit_ETH])
  useEffect(() => {
    if(values.select === 'stETH'){
      if (mintMax  && stETH_balance && totalLimit_stETH && depositAmount) {
      if (stETH_balance?.add(depositAmount).gt(totalLimit_stETH)) {
        form.setFields({
          'customInput': {
            error: {
              message: `Total max limit is ${totalLimit_stETH && formatNumber(formatFixed(totalLimit_stETH, 18), 4)} stETH`,
            },
          },
        });
        setDisabledBtn(true);
        return;
      }
      }
      setDisabledBtn(false)
    }
  }, [mintMax, values, stETH_balance, totalLimit_stETH, depositAmount])
  return (
    <div className="w-490 max-md:w-full">
      <div className="text-center">
        <div className="text-32 font-medium mt-2 max-md:mt-4 max-md:text-xl">aETH Minting</div>
        <div className="secTitle max-md:mb-4 max-md:text-xs">Mint aETH, Receive saETH.</div>
      </div>
      {(account && supportchain) && (
        <Typography
          className={classnames("white", { ModuleDark: context.Theme === 'dark', ModuleLight: context.Theme === '' })}
        >
          <div className="ModuleBgInner p-6 m-auto">
            <div className="white font22 font-semibold max-md:text-base">Balance</div>
            <div className="flex">
              <div className="flex-col flex-1 justify-around align-middle my-4 max-md:my-3">
                <div className="opacity-60 mb2-5 leading-normal text-sm">ETH balance</div>
                <div className="cursor-pointer text-22 font-semibold max-md:text-base">
                  {
                    balance &&
                    <Tooltip
                      position="bottom"
                      trigger="hover"
                      content={formateNormalNumber(formatEther(balance), 16)}
                    >
                      {formatNumber(formatEther(balance), 4) + " ETH"}
                    </Tooltip>
                  }
                </div>
              </div>
              <div className="flex-col flex-1 justify-around align-middle my-4 max-md:my-3">
                <div className="opacity-60 mb2-5 leading-normal text-sm">aETH balance</div>
                <div className="flex items-center gap-2">
                  <div className="cursor-pointer text-22 font-semibold max-md:text-base">
                    {
                      aETH_balance &&
                      <Tooltip
                        position="bottom"
                        trigger="hover"
                        content={formateNormalNumber(formatEther(aETH_balance), 16)}
                      >
                        {formatNumber(formatEther(aETH_balance), 4) + " aETH"}
                      </Tooltip>
                    }
                  </div>
                  <div className="AddTokens">
                    <Tooltip
                      position="bottom"
                      trigger="hover"
                      content="Add tokens to wallet"
                    >
                      <IconAddToken
                        className="cursor-pointer arco-icon-h5"
                        onClick={async (e) =>
                          await AddTokenToMetamask(
                            e,
                            (aETHConfig.networks as any)?.[
                              (chainId as number).toString()
                            ]?.address,
                            "aETH",
                            "18",
                            aETH,
                            connector.provider as Provider
                          )
                        }
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Row className="flex align-middle leading-8 mt-2">
          <Col className="grayNoFont" span={12}>ETH balance</Col>
          <Col className="grayNoFont" span={12}>aETH balance</Col>
        </Row>
        <Row className="flex align-middle leading-8">
          <Col span={12}>0.3472 ETH</Col>
          <Col span={12} >0.0 aETH</Col>
        </Row> */}
        </Typography>
      )}
      <Typography className={classnames("p-6 _shadowBig flex flex-col gap-4 fontColor max-md:p-4", { sectionBgNoRadius: chainId, sectionBg: !chainId })}>
        {/* <_Input key="Input1" onSelect={onSelect1} options={options1} maxValue={balance && Number((formatEther(balance))).toFixed(4)}/>
        <_Input2 key="Input2" onSelect={onSelect2} options={options2} value={value}/> */}
        <p className="max-md:text-base">{values2.select === "aETH" ? "Mint aETH" : "Mint & Stake saETH"}</p>
        <div className="inputBg">
          <Form
            className="p-4 MintSend max-md:p-3"
            form={form}
            autoComplete="off"
            onValuesChange={(_, v) => {
              setValues(v.customInput);
              setValueinModal(v.customInput?.input)
              setMintMax(false)
            }}
          >
            <p className="gray pb-2">Send</p>
            <Form.Item
              rules={[
                {
                  required: true,
                },
                {
                  validator: (val, cb) => {
                    // console.log(val.input);
                    if (val.input && parseFixed(val.input, 18).eq(0)) {
                      cb("Amount is required");
                    }
                    if (((val.select === 'ETH') && val.input && balance) && parseFixed(val.input, 18).gt(balance)) {
                      cb("ETH Insufficient liquidity");
                    }
                    // if (((val.select === 'ETH') && val.input && dailyLimit_ETH) && parseFixed(val.input, 18).gt(dailyLimit_ETH)) {
                    //   cb(`Daily max limit is ${dailyLimit_ETH && formatNumber(formatFixed(dailyLimit_ETH, 18), 4)} ETH`);
                    // }
                    if (((val.select === 'stETH') && val.input && stETH_balance) && parseFixed(val.input, 18).gt(stETH_balance)) {
                      cb("stETH Insufficient liquidity");
                    }
                    if (((val.select === 'stETH') && val.input && totalLimit_stETH && depositAmount && willReceiveRate) && parseFixed(val.input, 18).mul(willReceiveRate).div(BigNumber.from(10).pow(18)).add(depositAmount).gt(totalLimit_stETH)) {
                      // cb(`exceeding the depositCap.`);
                      cb(`Total max limit is ${totalLimit_stETH && formatNumber(formatFixed(totalLimit_stETH.sub(depositAmount), 18), 4)} ETH`);
                    }
                    // if (((val.select === 'stETH') && val.input && totalLimit_stETH && depositAmount && willReceiveRate) && parseFixed(val.input, 18).mul(willReceiveRate).div(BigNumber.from(10).pow(18)).add(depositAmount).gt(totalLimit_stETH)) {
                    //   // cb(`exceeding the depositCap.`);
                    //   cb(`Total max limit is ${totalLimit_stETH && formatNumber(formatFixed(totalLimit_stETH, 18), 4)} ETH`);
                    // }
                    // if (((val.select === 'stETH') && val.input && totalLimit_stETH) && parseFixed(val.input, 18).gt(totalLimit_stETH)) {
                    //   cb(`Total max limit is ${totalLimit_stETH && formatNumberNotCarry(Number(formatFixed(totalLimit_stETH, 18)))} ETH`);
                    // }
                    cb();
                  },
                },
              ]}
              extra={
                chainId &&
                (
                  (values.select === 'ETH') ?
                  <div className="flex justify-between items-center w-full">
                    <span className="cursor-pointer" onClick={() => { (balance && (setValues({ ...values, input: parseFloat(formateNormalNumber(formatEther(balance), 8)).toString() }), setValueinModal(formatEther(balance)), setMintMax(true))) }}>
                      Max:{" "}
                      {balance ? formatNumber(formatEther(balance), 4) : "-"}
                    </span>
                    {values.input && context.Data?.ETHPrice && <span>≈&nbsp;{formatNumber(formatFixed(parseFixed(values.input, 18).mul(parseFixed(context.Data?.ETHPrice,18)).div(BigNumber.from(10).pow(18)), 18), 2)}</span>}
                  </div>
                    : 
                    <div className="flex justify-between items-center w-full">
                      <span className="cursor-pointer" onClick={() => { stETH_balance && (setValues({ ...values, input: parseFloat(formateNormalNumber(formatEther(stETH_balance), 8)).toString() }), setValueinModal(formatEther(stETH_balance)), setMintMax(true)) }}>
                      Max:{" "}
                      {stETH_balance ? formatNumber(formatEther(stETH_balance), 4) : "-"}
                    </span>
                    {values.input && context.Data?.stETHPrice && <span>≈&nbsp;{formatNumber(formatFixed(parseFixed(values.input, 18).mul(parseFixed(context.Data?.stETHPrice,18)).div(BigNumber.from(10).pow(18)), 18), 2)}</span>}
                    </div>
                )
              }
              className="mb0"
              // labelCol={{ span: 2 }}
              wrapperCol={{ span: 24 }}
              initialValue={values}
              // label={
              //   <Image
              //     width={30}
              //     src={img[values.select]}
              //     alt={values.select}
              //     preview={false}
              //   />
              // }
              field="customInput"
            >
              {/* <CustomInput options={["ETH", "stETH"]} val={values} /> */}
              <CustomInput options={[
                {
                label:<><Image
                  // width={30}
                  className="w-30 mr-2 max-md:mr-1 max-md:w-6"
                  src={img['ETH']}
                  alt='ETH'
                  preview={false}
                /><span>ETH</span></>,
                value:'ETH'
                },
                {
                label:<><Image
                  // width={30}
                  className="w-30 mr-2 max-md:mr-1 max-md:w-6"
                  src={img['stETH']}
                  alt='stETH'
                  preview={false}
                /><span>stETH</span></>,
                value:'stETH'
                }]} val={values} />

            </Form.Item>
          </Form>
          {/* <Typography.Paragraph>
            <p>Form Data:</p>
            <pre>{JSON.stringify(values, null, 2)}</pre>
            <pre>{values?.input && JSON.stringify(parseFixed(values?.input, 18).toString(), null, 2)}</pre>
            <pre>{values?.input && JSON.stringify(balance?.toString(), null, 2)}</pre>
            <pre>111:{values?.input && JSON.stringify(depositAmount?.toString(), null, 2)}</pre>
          </Typography.Paragraph> */}
        </div>
        <div className="inputBg MintReceive">
          <Form form={form1} className="p-4 max-md:p-3" autoComplete='off' onValuesChange={(_, v) => { setValues2(v.customInput2);setValueinModal2(v.customInput2.input) }}>
            <p className='gray pb-2'>Recieve</p>
            <Form.Item
            extra={
              chainId &&
              (
                values2.select === 'aETH' ?
                <div className="flex justify-end items-center w-full">
                  {values2.input && context.Data?.aETHPrice && <span>≈&nbsp;{formatNumber(formatFixed(parseFixed(values2.input, 18).mul(parseFixed(context.Data?.aETHPrice,18)).div(BigNumber.from(10).pow(18)), 18), 2)}</span>}
                </div>
                  : 
                  <div className="flex justify-end items-center w-full">
                  {values2.input && context.Data?.saETHPrice && <span>≈&nbsp;{formatNumber(formatFixed(parseFixed(values2.input, 18).mul(parseFixed(context.Data?.saETHPrice,18)).div(BigNumber.from(10).pow(18)), 18), 2)}</span>}
                  </div>
              )
            }
              className='mb0'
              // labelCol={{ span: 2 }}
              wrapperCol={{ span: 24 }}
              initialValue={values2}
              // label={
              //   <Image
              //     width={30}
              //     src={img[values2.select]}
              //     alt={values2.select}
              //     preview={false}
              //   />
              // }
              field='customInput2'
            >
              {/* <CustomInput2 options={options2} val={values2} /> */}
              <CustomInput2 options={_Options2} val={values2} />
            </Form.Item>
          </Form>
        </div>
        <div className="gap-0 text-sm max-md:text-xs">
        <Row className="flex align-middle leading-8">
          <Col className="grayNoFont" span={12}>
            Exchange rate
          </Col>
          <Col span={12} className="text-end">
            1{values.select} = {willReceiveRate ? parseFloat(formatNumber(formatFixed(willReceiveRate, 18), 4)) : ''} {values2.select}
          </Col>
        </Row>
        <Row className="flex align-middle leading-8">
          <Col className="grayNoFont" span={12}>
            Max transaction cost
          </Col>
          <Col span={12} className="text-end">
          { (chainId && supportchain && maxConst) ? '$' + formatNumber(formatFixed(maxConst,26), 4) : ""}
          </Col>
        </Row>
        <Row className="flex align-middle leading-8">
          <Col className="grayNoFont" span={12}>
            You will receive
          </Col>
          <Col span={12} className="text-end">
            {values2.input ? formatNumber(values2.input, 8) : '-'} {values2.select}
          </Col>
        </Row>
        </div>
        {(chainId && supportchain) ? (
          values2.select === "aETH" ? (
            values.select === "ETH" ?
              <div
                className={classnames("w-full py-4 text-base text-center rounded-xl cursor-pointer btnBg", {disabledNoGray:disabledBtn || !values.input, disabled: minting })}
                onClick={OnETH_aETH}
              >
                Mint
              </div>
              : (!stETH_allowance || (stETH_allowance?.eq('0') || (values.input && stETH_allowance.lt(parseFixed(values.input, 18))))) ?
                <div
                  className={classnames('w-full py-4 text-base text-center rounded-xl cursor-pointer btnBg', { disabled: approving })}
                  onClick={OnstETHApprove}
                >
                  Approve
                </div>
                : <div
                  className={classnames("w-full py-4 text-base text-center rounded-xl cursor-pointer btnBg", { disabledNoGray:disabledBtn || !values.input,disabled: minting })}
                  onClick={OnstETH_aETH}
                >
                  Mint
                </div>
          ) : (
            <div className={classnames("w-full py-4 text-base text-center rounded-xl cursor-pointer borderBg", {disabledNoGray:disabledBtn || !values.input, disabled: minting })}>
              <div className="fontLinear" onClick={OnETH_saETH}>Mint & Stake</div>
            </div>
          )
        ) : (
          <div
            className="w-full py-4 text-base text-center rounded-xl cursor-pointer btnBg"
            onClick={() => setVisible(true)}
          >
            Connect wallet
          </div>
        )}
      </Typography>
      <Typography className="p-6 pt-1 sectionBg mt-6 _shadowSmall fontColor text-sm max-md:p-4 max-md:text-xs max-md:mt-4">
        <Title className="fontColor" heading={5}>Aspida statistics</Title>
        <Row className="flex align-middle leading-8 mt-4">
          <Col className="grayNoFont" span={12}>
            Total aETH minted
          </Col>
          <Col span={12} className="text-end">
            {/* {(chainId && totalaETHMinted) ? formatNumber(formatFixed(totalaETHMinted, 18), 4) : ''} */}
            {context.Data?.totalMintedTokens ? formatNumber(formatFixed(context.Data?.totalMintedTokens, 18), 4) : ''}
          </Col>
        </Row>
        {/* <Row className="flex align-middle leading-8">
          <Col className="grayNoFont" span={12}>
            Max transaction cost
          </Col>
          <Col span={12} className="text-end">
          { (chainId && supportchain && maxConst) ? '$' + formatNumber(formatFixed(maxConst,26), 4) : ""}
          </Col>
        </Row> */}
        <Row className="flex align-middle leading-8">
          <Col className="grayNoFont" span={12}>
            aETH market cap
          </Col>
          <Col span={12} className="text-end">
            {/* {(chainId && aETHMarketCap && context.ETHFeed) ? '$' + formatNumber(formatFixed(aETHMarketCap, (18 + Number(context.ETHFeed.decimals))), 4) : ''} */}
            {context.Data?.totalMintedTokensAmount ? `$ ${formatNumber(context.Data?.totalMintedTokensAmount,4)}` : ''}
          </Col>
        </Row>
      </Typography>

      {/* Mint Modal */}
      <Modal
        // title={
        //   <Typography className="text-left">
        //   </Typography>
        // }
        visible={mintvisible}
        onOk={() => setMintvisible(false)}
        onCancel={() => setMintvisible(false)}
        autoFocus={false}
        maskClosable={false}
        footer={null}
        // closeIcon={<img src={Close}/>}
      >
        {
          <div className="flex flex-col justify-center items-center mt-6 gap-4 max-md:gap-3">
            <div className={classnames("IconSyncStake flex  flex-row justify-center items-center cursor-pointer", { pendingBg: mintTransactionStatus === 0, successBg: mintTransactionStatus === 1, failBg: mintTransactionStatus === 2, })} style={{ color: '#fff' }}>
              {
                mintTransactionStatus === 0 ?
                  // <img src={sync} className="rotate"/>
                  <div className="rotate">
                  <IconResearch width="60" height="60"/>
                  </div>
                  // <IconSync fontSize={28} spin={false} />
                  : mintTransactionStatus === 1 ?
                    // <img src={success}/>
                    <IconDone width="60" height="60"/>
                    : <IconFail width="60" height="60"/>
                    // <img src={fail}/>
              }
            </div>
            <div className="font26 max-md:text-base">
            {
              mintTransactionStatus === 0 ?
              
                <span>{values2.select === "aETH" ? "Minting" : "Mint & Stake"} {formatNumber(parseFloat(valueinModal2), 4)} {values2.select}</span>
                : mintTransactionStatus === 1 ?
                  <span>Successfully {values2.select === "aETH" ? "minted" : "minted & staked"} {formatNumber(parseFloat(valueinModal2), 4)} {values2.select}</span>
                  : mintTransactionStatus === 2 ? <span>
                    {/* Cancelled by user */}
                    {errorTxt?.reason ?? errorTxt}
                  </span>
                  :<span>
                    {errorTxt}
                  </span>
              
            }
            </div>
            {
              mintTransactionStatus === 0 &&
              <div className="_flex flex-row justify-center items-center gap-2 text-sm max-md:text-xs">
                <div className="flex items-center">
                  <img className="inline-block mr-1" width={20} src={img[values.select]} />
                  <span className="mr-1">{formatNumber(parseFloat(valueinModal), 4)}</span>
                  <span>{values.select}</span>
                </div>
                <div className="IconArrowRight flex justify-center items-center">
                  {/* <IconArrowRight /> */}
                  <IconDarkAArriveB />
                </div>
                <div className="flex items-center">
                  <img className="inline-block mr-1" width={20} src={img[values2.select]} />
                  <span className="mr-1">{formatNumber(parseFloat(valueinModal2), 4)}</span>
                  {/* <span>{valueinModal}</span>
                  <span className="mr-1">{willReceiveRate && formatNumber(parseFloat(Number(formatEther(parseFixed(valueinModal, 18).mul(willReceiveRate).div(BigNumber.from(10).pow(18)))).toFixed(8)).toString(), 4)}</span> */}
                  <span>{values2.select}</span>
                </div>
              </div>
            }
            <div className="flex flex-col items-center grayNoFont font20 pt-5 max-md:text-xs max-md:pt-3">
            {
              mintTransactionStatus === 0 ?
                <div>
                  Confirm this transaction in your wallet
                </div>
                : mintTransactionStatus == 1 ?
                  <div>
                    Earn rewards by staking aETH/saETH
                  </div>
                  : mintTransactionStatus == 2 ? <div className="gray pt-5">
                    {/* User denied the transaction signature */}
                    {/* {JSON.stringify(errorTxt, null, 2)} */}
                    {errorTxt?.reason ?? errorTxt}
                  </div>
                  :<div>
                    Please re-initiate the request
                  </div>
            }
            {
              ((mintTransactionStatus === 1) || (mintTransactionStatus === 3)) &&
              <a href={`${(TX_LINK as any)[chainId as number]}${hash}`} target="_blank" rel="noreferrer" className="flex items-center ml-3" style={{ color: '#3B82F6' }}>
                {/* <IconLaunch /> */}
                <span className="mr-1">View on Etherscan</span>
                <IconExternalLink />
              </a>
            }
            </div>
          </div>
        }
      </Modal>
    </div>
  );
};

export default Mint;
