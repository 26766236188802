import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { GnosisSafe } from '@web3-react/gnosis-safe'
import { MetaMask } from '@web3-react/metamask'
import { Network } from '@web3-react/network'
import type { Connector, Provider } from '@web3-react/types'

import { WalletConnect as WalletConnect } from '@web3-react/walletconnect'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'
import { Provider as _Provider } from '@ethersproject/abstract-provider'
import { Contract, ContractInterface } from '@ethersproject/contracts'
import { BigNumber, ethers } from 'ethers'
import './multicall.d.ts'
import { aggregate, createWatcher } from "@makerdao/multicall"
export function getName(connector: Connector) {
  if (connector instanceof MetaMask) return 'MetaMask'
  if (connector instanceof WalletConnectV2) return 'WalletConnect'
  if (connector instanceof WalletConnect) return 'WalletConnect V1'
  if (connector instanceof CoinbaseWallet) return 'Coinbase'
  if (connector instanceof Network) return 'Network'
  if (connector instanceof GnosisSafe) return 'Gnosis Safe'
  return 'Unknown'
}
// 初始化 Contract
export const initContract = (resolverAddress: string, Abi: ContractInterface, provider: _Provider): Contract => {
  // console.log("initContract",new ethers.Contract(resolverAddress, Abi, provider))
  return new ethers.Contract(resolverAddress, Abi, provider)

}
// 初始化 Contract

// 初始化 Contract
export const _initContract = (resolverAddress: string, Abi: ContractInterface): Contract => {
  // console.log("initContract",new ethers.Contract(resolverAddress, Abi))
  return new ethers.Contract(resolverAddress, Abi)

}
// 初始化 Contract
// mutical
export const multicall_target: Record<number, string> = {
  1: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
  42: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  137: '0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507',
  56: '0x294Cf1d64599b5F56D63b3DBe461f985bc5e1254',
  10: '0x7e2Dc2b896b7AAc98D6ee8e954d3f5bDCC90076b',
  42161: '0xF3919fcEE9863E1Df6dAc2D1e661A3b7e540D1C9',
  5: '0xcA11bde05977b3631167028862bE2a173976CA11',
  11155111: '0xcA11bde05977b3631167028862bE2a173976CA11',
}
// multicall methods
export const multicall = async (rpcUrl: any, multicallAddress: string, calls_array: Record<string, any>[]) => {
  const config = {
    rpcUrl,
    multicallAddress
  };
  // console.log(calls_array)
  const calls = calls_array.map((item: any) => {
    return {
      target: item.target,
      call: item.call,
      returns: item.returns ? item.returns : [[`${item.key}`, (val: any) => val]]
    }
  })
  // console.log(calls, config)
  try {
    const result = await aggregate(calls, config);
    return result
  } catch (error) {
    console.log(error)
  }
}
export const AddTokenToMetamask = async (
  // e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  e: any,
  address: string | undefined,
  symbol: string | undefined,
  decimals: React.ReactText,
  image: string | undefined,
  provider: Provider
) => {
  e.preventDefault();
  e.stopPropagation();
  console.log(image);
  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await provider.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address, // The address that the token is at.
          symbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals, // The number of decimals in the token
          image: `${window.location.origin}${image}`, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      console.log("Thanks for your interest!");
    } else {
      console.log("Your loss!");
    }
  } catch (error) {
    console.log(error);
  }
};
// 正则 formate number  不进位
export const formateNormalNumber = (number: any, precision: number = 4) => {
  try {
    const str = number?.toString()
    switch (precision) {
      case 2:
        return str.replace(/^(.*\..{2}).*$/, "$1");
        break;
      case 4:
        return str.replace(/^(.*\..{4}).*$/, "$1");
        break;
      case 6:
        return str.replace(/^(.*\..{6}).*$/, "$1");
        break;
      case 8:
        return str.replace(/^(.*\..{8}).*$/, "$1");
        break;
        case 16:
          return str.replace(/^(.*\..{16}).*$/, "$1");
          break;
      default:
        return str.replace(/^(.*\..{4}).*$/, "$1");
        break;
    }
  } catch (error) {
    console.log(error)
  }
}
// 显示千分位 不进位
export const formatNumber = (num: any, point: number) => {
  try {
    num = num?.toString()
    let reg = num.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(\d{3})+$)/g
    // return num.replace(reg, '$1,').replace(/(?<=\.\d{0}).*$/, '')
    switch (point) {
      case 2:
        return num.replace(reg, '$1,').replace(/(?<=\.\d{2}).*$/, '')
        break;
      case 4:
        return num.replace(reg, '$1,').replace(/(?<=\.\d{4}).*$/, '')
        break;
      case 6:
        return num.replace(reg, '$1,').replace(/(?<=\.\d{6}).*$/, '')
        break;
      case 8:
        return num.replace(reg, '$1,').replace(/(?<=\.\d{8}).*$/, '')
        break;
      default:
        return num.replace(reg, '$1,').replace(/(?<=\.\d{2}).*$/, '')
        break;
    }
  } catch (error) {
    console.log(error)
  }

}
// 聚合 formate   abbr | percent | string
export const formatNumberNotCarry = (number: number, type: string = '', minPrecision: number = 3, maxPrecision: number = 3): string => {
  const options = {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  }
  if (type === 'abbr') {
    if (number === 0) {
      return number.toLocaleString(undefined, options).slice(0, -1)
    }
    const SI_SYMBOLS = ["", "K", "M", "G", "T", "P", "E"];
    const tier = Math.floor(Math.log10(Math.abs(number)) / 3);
    const suffix = SI_SYMBOLS[tier];
    const scale = 10 ** (tier * 3);
    const scaled = number / scale;
    // return scaled.toLocaleString(undefined, options).slice(0, -1) + suffix;
    return formatNumber(scaled, minPrecision) + suffix
  }
  if (type === 'percent') {
    if (Math.abs(number) < 0.0001) {
      if (number > 0) {
        return '< 0.01%'
      } else {
        return '> -0.01%'
      }
    }
    if (Math.abs(number) > 9999.99) {
      if (number > 0) {
        return '> 999,999.00%'
      } else {
        return '< -999,999.00%'
      }
    }
    // return number.toLocaleString('zh', { ...options, style: 'percent' }).slice(0, -2) + '%'
    return formatNumber(number * 100, minPrecision) + '%'
  }
  // return number.toLocaleString(undefined, options).slice(0, -1)
  return formatNumber(number, minPrecision)
}