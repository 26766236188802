import { useCallback, useEffect, useState } from 'react'
import { BigNumber } from "@ethersproject/bignumber";
import {
  useWeb3React
} from "@web3-react/core";
import { Provider } from '@ethersproject/abstract-provider'
import { initContract } from "../utils";

const useAllowance = (TokenConfig:any,spenderTokenConfig: any,refresh:number = 0,IntervalTime:number = 10) => {
  const [allowance, setAllowance] = useState<BigNumber>()
  const { provider, account,chainId } = useWeb3React();

  const spenderAddress = (spenderTokenConfig.networks as any)?.[(chainId as number)?.toString()]?.address

  const getAllowance = async (
    account: string,
    spenderAddress: string,
    provider: Provider
  ): Promise<string> => {
    try {
      const tokenContract = initContract((TokenConfig.networks as any)?.[(chainId as number).toString()]?.address, TokenConfig.abi, provider);
      const allowance: string = await tokenContract.allowance(account, spenderAddress)
      // console.log("try: ", allowance)
      return allowance;
    } catch (e) {
      // console.log("catch: eeeee",e)
      return "0";
    }
  };

  const fetchAllowance = useCallback(async (account: string, provider: any) => {
    if (!spenderAddress || !account || !provider) {
      return
    }
    const allowance = await getAllowance(account, spenderAddress, provider)
    setAllowance(BigNumber.from(allowance))
  }, [setAllowance, spenderAddress])


  useEffect(() => {
    if (account && provider && spenderAddress) {
      fetchAllowance(account, provider)

      let refreshInterval = setInterval(() => {
        fetchAllowance(account, provider)
      }, 1000 * IntervalTime)
      return () => clearInterval(refreshInterval)
    }
  }, [account, provider, spenderAddress,refresh])

  return allowance
}

export default useAllowance